import { AnyAction } from 'redux'

import { createUser, IUser } from 'lib/entities/User'
import { createClient, IClient } from 'lib/entities/Client'

export const LOG_IN = 'AUTH/LOG_IN'
export const LOG_OUT = 'AUTH/LOG_OUT'
export const SET_PROFILE = 'AUTH/SET_PROFILE'
export const SET_TOKEN = 'AUTH/SET_TOKEN'
export const SET_SELECTED_CLIENT = 'AUTH/SET_SELECTED_CLIENT'

export interface IState {
	currentUser: IUser
	token: string
	selectedClient: IClient
}

const initialState: IState = {
	currentUser: createUser(),
	selectedClient: createClient(),
	token: '',
}

export default function authReducer(state: IState = initialState, action: AnyAction): IState {
	switch (action.type) {
	case SET_TOKEN:
		return {
			...state,
			token: action.token,
		}

	case LOG_OUT:
		return initialState

	case SET_PROFILE:
		return {
			...state,
			currentUser: createUser(action.data),
		}

	case SET_SELECTED_CLIENT:
		return {
			...state,
			selectedClient: action.client,
		}

	default:
		return state
	}
}
