import { AxiosResponse, AxiosRequestConfig } from 'axios'

export default interface IResponse extends AxiosResponse{
	isSuccessful: boolean
	data: any
	status: number
	statusText: string
	headers: any
	config: AxiosRequestConfig
	request: any,
}

export function createResponse(props: AxiosResponse): IResponse {
	return {
		...props,
		data: props.data || {},
		status: props.status || 0,
		statusText: props.statusText || '',
		config: props.config || {},
		headers: props.headers || {},
		request: props.request || {},
		isSuccessful: props.status >= 200 && props.status < 400,
	}
}
