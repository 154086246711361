import { makeStyles } from '@material-ui/styles'
import { orange, yellow12 } from 'lib/colors'

export const useStyle = makeStyles({
	open: {
		minWidth: 24,
		height: 24,
		backgroundColor: yellow12,
		borderRadius: '100%',
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'&::after': {
			content: '""',
			position: 'absolute',
			width: 16,
			height: 16,
			backgroundColor: orange,
			borderRadius: '100%',
		},
	},
})
