import { makeStyles } from '@material-ui/core'
import { dark48, white } from 'lib/colors'

export const useStyle = makeStyles({
	imageContainer: {
		height: 470,
		position: 'relative',
		'&:after': {
			content: '""',
			position: 'absolute',
			display: 'block',
			width: '100%',
			height: 50,
			bottom: 0,
			background: 'linear-gradient(180deg, rgba(41, 50, 65, 0) 0%, rgba(41, 50, 65, 0.15) 100%)',
		},
		width: '100%',
		'& img': {
			zIndex: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	},
	imageInfo: {
		color: dark48,
		height: 168,
		backgroundColor: white,
		width: '100%',
		padding: 24,
		'& svg': {
			height: 14,
		},
	},
	imageInfoTop: {
		marginBottom: 48,
	},
	imageInfoRow: {
		marginBottom: 10,
	},
	imageInfoLabel: {
		display: 'block',
		width: 200,
	},
	lightText: {
		color: dark48,
	},
})
