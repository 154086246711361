import {
	Row,
	Icons,
	PrimaryButton,
	Typography,
	Modal,
	ModalSection,
	InvisibleButton,
	DangerButton,
	MenuDropdown,
} from '@copyrightagent/basic-components'
import { red } from 'lib/colors'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Action, RejectReason } from 'routes/Private/Cleaning/api'
import { useStyle } from './style'

interface ICleaningActionsProps {
	imageViolationsCount: number
	selectedViolationsCount: number
	onToggleSelectAll(): void
	onDiscardViolations(rejectReason?: RejectReason): void
	onApproveViolations(): void
}

export default function CleaningActionsBanner(props: ICleaningActionsProps): ReactElement {
	const {
		imageViolationsCount,
		selectedViolationsCount,
		onToggleSelectAll,
		onDiscardViolations,
		onApproveViolations,
	} = props

	const classes = useStyle()
	const { t } = useTranslation('cleaning')

	const [count, setCount] = useState<number>()
	const [rejectReason, setRejectReason] = useState<RejectReason | undefined>()
	const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false)
	const [action, setAction] = useState<Action | undefined>()

	const options = Object.values(RejectReason).map(reason => (t(`cleaning:rejectReasons/${reason}`)))

	// Sets count
	useEffect(() => {
		if (selectedViolationsCount === 0) return setCount(imageViolationsCount)
		return setCount(selectedViolationsCount)
	}, [imageViolationsCount, selectedViolationsCount])

	const handleClose = () => {
		setConfirmationModalOpen(false)
	}

	const approveViolations = () => {
		setAction(Action.Violating)
		setConfirmationModalOpen(true)
	}

	const discardViolations = () => {
		setAction(Action.NotViolating)
		setConfirmationModalOpen(true)
	}

	const confirmApproveViolations = () => {
		onApproveViolations()
		handleClose()
	}

	const confirmDiscardViolations = () => {
		onDiscardViolations(rejectReason)
		setRejectReason(undefined)
		handleClose()
	}

	const handleSelectToggle = () => {
		onToggleSelectAll()
		setRejectReason(undefined)
	}

	const getConfirmationModalText = (): React.ReactNode => {
		if (action === Action.Violating) return t('cleaning:createViolationsDescription')

		if (rejectReason) {
			return (<>
				{t('cleaning:rejectViolationsDescription')}
				<span className={classes.reason}> {`'${t(`cleaning:rejectReasons/${rejectReason}`)}'.`}</span>
			</>
			)
		}
		return t('cleaning:rejectViolationsDescriptionNoReason')
	}

	const handleSelectRejectedReason = (index: number) => {
		setRejectReason(Object.values(RejectReason)[index])
	}

	return (
		<>
			<div className={classes.actionBannerContainer}>
				<Row className={classes.actionBanner} justify='flex-start'>
					<Row className={classes.numberOfViolations}>
						<Typography variant='h4'>{count}</Typography>
					</Row>
					<Typography variant='subtitle1' className={classes.actionLabel}>
						{count === imageViolationsCount
							? t('cleaning:violations')
							: t('cleaning:selected')}
					</Typography>
					<Row className={classes.actionCalls}>
						{selectedViolationsCount > 0
						&& <MenuDropdown
							triggerText={t('cleaning:rejectReasons')}
							options={options}
							onSetSelectedIndex={handleSelectRejectedReason}
						/>
						}
						<InvisibleButton
							text={
								selectedViolationsCount === imageViolationsCount
									? t('cleaning:deselectAll')
									: t('cleaning:selectAll')
							}
							onClick={handleSelectToggle}
							className={classes.selectToggle}
						/>
						{selectedViolationsCount > 0
							? <DangerButton
								onClick={discardViolations}
								className={classes.rejectButton}
							>
								<Icons.TrashIcon color={red}/>
							</DangerButton>
							: <PrimaryButton
								type='submit'
								text={t('cleaning:submitViolations')}
								className={classes.submitButton}
								onClick={approveViolations}
							/>
						}
					</Row>
				</Row>
			</div>
			{confirmationModalOpen
				&& <Modal
					size='small'
					title={action === Action.NotViolating
						? t('cleaning:rejectViolation')
						: t('cleaning:createViolations')}
					titleText={getConfirmationModalText()}
					open={confirmationModalOpen}
					onClose={handleClose}
				>
					<ModalSection>
						<div className={classes.confirmModalNumber}>
							<Typography variant='h5'>{count}</Typography>
						</div>
					</ModalSection>
					<ModalSection>
						<div className={classes.confirmModalFooter}>
							<InvisibleButton text={t('general:close')}
								onClick={handleClose}
								className={classes.confirmModalCloseButton}
							/>
							{action === Action.Violating
								? <PrimaryButton
									text={t('cleaning:submitViolations')}
									onClick={confirmApproveViolations}
								/>
								: <DangerButton
									onClick={confirmDiscardViolations}
									text = {t('cleaning:rejectViolation')}
								/>
							}
						</div>
					</ModalSection>
				</Modal>
			}
		</>
	)
}
