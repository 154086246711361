/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { Icons } from '@copyrightagent/basic-components'
import { dark48 } from 'lib/colors'
import { useStyle } from './style'

export interface IImageProps {
	className?: string
	src: string
	alt?: string
	srcAlternative?: string
	onLoad?(...args: any): void
	onError?(): void
}

export default function ImageComponent(props: IImageProps): ReactElement {
	const { src, alt, className, srcAlternative, onLoad, onError } = props

	const classes = useStyle()

	const [imageSrc, setImageSrc] = useState(src)
	const [loaded, setLoaded] = useState(false)
	const [isImageFound, setIsImageFound] = useState(false)

	// Sets image source
	useEffect(() => {
		if (!isImageFound && srcAlternative) setImageSrc(srcAlternative)
		setImageSrc(src)
	}, [isImageFound, src, srcAlternative])

	// Sets if image is found and if loaded
	useEffect(() => {
		if (onLoad) onLoad(false)
		const image = new Image()
		if (imageSrc) image.src = imageSrc

		if (!imageSrc) {
			setLoaded(true)
			setIsImageFound(false)
		}

		const handleError = () => {
			if (onError) onError()
			setIsImageFound(false)
			setLoaded(true)
		}
		const handleLoad = () => {
			setIsImageFound(true)
			setLoaded(true)
			if (onLoad) onLoad(true)
		}

		image.addEventListener('error', handleError)
		image.addEventListener('load', handleLoad)

		return () => {
			image.removeEventListener('error', handleError)
			image.removeEventListener('load', handleLoad)
		}
	}, [onLoad, imageSrc, onError])

	const renderImage = useMemo(() => {
		if (!loaded) {
			return (
				<div className={`${classes.root} ${className}`} />
			)
		}
		return (
			<>
				{isImageFound
					? <img
						src={imageSrc}
						className={`${classes.root} ${className}`}
						alt={alt || ''}
					/>

					: <div className={classes.noScreenshot}>
						<Icons.ImageIcon color={dark48} size="small"/>
					</div>
				}
			</>
		)
	}, [alt, className, classes.noScreenshot, classes.root, isImageFound, loaded, imageSrc])

	return renderImage
}
