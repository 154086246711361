import { Icons } from '@copyrightagent/basic-components'
import clsx from 'clsx'
import ButtonLink from 'components/ButtonLink'
import ImageComponent from 'components/ImageComponent'
import LinkIcon from 'components/LinkIcon'
import { dark72 } from 'lib/colors'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyle } from './style'

interface ICompareScreenshotProps {
	image: string
	violationUrl: string
	previewMode: boolean
	addingMode: boolean
	onChangeScreenshot(): void
	setToFullMode(): void
}

export default function CompareScreenshot(props: ICompareScreenshotProps): ReactElement {
	const { image, violationUrl, previewMode, addingMode, onChangeScreenshot, setToFullMode } = props

	const classes = useStyle({ addingMode })
	const { t } = useTranslation('cleaning')

	const addScreenshot = () => {
		onChangeScreenshot()
	}

	const switchToFullMode = () => {
		if (previewMode) setToFullMode()
	}

	return (
		<div
			className={clsx(previewMode ? classes.preview : classes.screenshotContainer)}
			onClick={switchToFullMode}
		>
			<ImageComponent
				className={classes.imageScreenshot}
				src={image}
				alt={t('potentialViolation')}
			/>
			{(!previewMode && !addingMode)
				&& <div className={classes.buttonContainer}>
					{ image
						? <div className={classes.iconButtonContainer}>
							<ButtonLink text={t('replaceScreenshot')} onClick={addScreenshot}>
								<Icons.EditIcon color={dark72} size="small"/>
							</ButtonLink>
						</div>

						: <div className={classes.iconButtonContainer}>
							<ButtonLink text={t('addScreenshot')} onClick={addScreenshot}>
								<Icons.EditIcon color={dark72} size="small"/>
							</ButtonLink>
						</div>
					}
					<div className={classes.iconButtonContainer}>
						<LinkIcon href={violationUrl} targetBlank={true}>
							<Icons.LinkIcon external color={dark72} size="small"/>
						</LinkIcon>
					</div>
				</div>
			}

		</div>
	)
}
