export enum RouteUrls {
	landingPage = '/',
	login = '/login',
	logout = '/logout',
	loggingIn = '/loggingIn',
	dashboard = '/dashboard',
	search = '/search',
	settings = '/settings',
	cleaning = '/cleaning',
	users = '/settings/users',
	violations = '/settings/cases',
	security = '/settings/security',
	notifications = '/notifications',
	adminReview = '/admin/review/list',
	dataEnrichment = '/data-enrichment',
	initialReview = '/initial-review-list',
	userDetails = '/settings/user-details',
}
