import { makeStyles } from '@material-ui/core'
import { dark4 } from 'lib/colors'

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		paddingLeft: 48,
		paddingRight: 48,
		height: '100%',
	},
	header: {
		width: '100%',
		zIndex: 2,
		paddingTop: 48,
		position: 'sticky',
		top: 0,
		backgroundColor: dark4,
	},
	title: {
		marginBottom: 24,
	},
	body: {
		flex: 1,
	},
	scrollable: {
		overflow: 'auto',
	},
})
