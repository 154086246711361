import { makeStyles } from '@material-ui/styles'
import { blue, blue12, red } from 'lib/colors'

export const useStyle = makeStyles({
	addScreenshotContainer: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: 1,
		background: 'rgba(255, 255, 255, 0.92)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	droppingContainer: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	submitButtonContainer: {
		position: 'absolute',
		bottom: -64,
		right: 68,
	},
	saveButton: {
		alignSelf: 'flex-end',
		marginTop: 12,
		width: 100,
		padding: 10,
	},
	dropZone: {
		backgroundColor: blue12,
		border: `1px dashed ${blue}`,
		borderRadius: 6,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: 330,
		width: 500,
		cursor: 'pointer',
		position: 'relative',
		'&:focus': {
			outline: 'none',
		},
		'& p': {
			paddingTop: 12,
			fontWeight: 600,
			color: blue,
		},
	},
	preview: {
		width: '100%',
		height: '100%',
		borderRadius: 6,
		position: 'absolute',
		objectFit: 'cover',
	},
	errorColumn: {
		'& p': {
			color: red,
		},
	},
	buttonContainer: {
		cursor: 'pointer',
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		zIndex: 2,
		height: 53,
		background: 'linear-gradient(180deg, rgba(41, 50, 65, 0) 0%, rgba(41, 50, 65, 0.15) 100%)',
		borderRadius: '0 0 6px 6px',
	},
	iconButtonContainer: {
		margin: 3,
	},
})
