import { makeStyles } from '@material-ui/styles'
import { dark12 } from 'lib/colors'

export const useStyle = makeStyles({
	comparisonColumn: {
		position: 'relative',
		width: 596,
		height: 596,
		backgroundColor: dark12,
		borderRadius: 6,
	},
	leftColumn: {
		marginRight: 12,
	},
	imageInCompare: {
		borderRadius: 6,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	closeButton: {
		maxWidth: 61,
		height: 40,
		paddingLeft: 12,
		paddingRight: 12,
	},
	footer: {
		paddingTop: 24,
	},
	label: {
		zIndex: 5,
	},
	iconButtonContainer: {
		margin: 3,
	},
	submitButton: {
		padding: 10,
		marginRight: 8,
	},
})
