import React, { ReactElement, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	Row,
	PageBody,
	Typography,
} from '@copyrightagent/basic-components'
import { useTranslation } from 'react-i18next'
import { getUser, deleteUser } from './api'
import SortableTable from './SortableTable'

const useStyle = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		height: '100%',
		maxHeight: 1105,
		width: '100%',
		borderRadius: 6,
		marginTop: 0,
		backgroundColor: 'white',
		padding: '24px !important',
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
	title: {
		height: 33,
		marginBottom: 40,
		paddingBottom: 12,
	},
})

interface IColumn {
	id: 'fullName' | 'email' | 'permission_level'
	label: string
	minWidth?: number
}

const UsersSection = (): ReactElement => {
	const classes = useStyle()
	const { t } = useTranslation('settings')

	const [editUserOpened, setEditUserOpened] = useState(false)
	const [users, setUsers] = useState([])

	const columns: IColumn[] = [
		{ id: 'fullName', label: t('users/table/label/name'), minWidth: 150 },
		{ id: 'email', label: t('users/table/label/email'), minWidth: 140 },
		{ id: 'permission_level', label: t('users/table/label/userType'), minWidth: 100 },
	]

	const onDeleteUser = async (id: string) => {
		await deleteUser(id)
	}

	const toggleEditModal = () => {
		setEditUserOpened(!editUserOpened)
	}

	const onEditUser = async (id: string) => {
		const response = await getUser(id)
		toggleEditModal()
	}

	return (
		<PageBody className={classes.root}>
			<Row align="start" justify="space-between">
				<Typography className={classes.title} variant="h5" fontWeight="bolder">{t('users/title')}</Typography>
			</Row>
			<SortableTable
				rows={users}
				columns={columns}
				onDeleteUser={onDeleteUser}
				onEditUser={onEditUser}
			/>
		</PageBody>
	)
}

export default UsersSection
