import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import SubMenu from 'components/SubMenu'
import MainLayout from 'components/MainLayout'
import settingsMenuItems from './settingsMenuItems'

const SettingsPage = (): ReactElement => {
	const { t } = useTranslation('settings')

	return (
		<MainLayout title={t('pageTitle')} >
			<SubMenu title={t('pageSubTitle')} menuItems={settingsMenuItems()}/>
		</MainLayout>
	)
}

export default SettingsPage
