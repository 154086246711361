import React, { ReactElement, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles({
	card: {
		display: 'flex',
		flexFlow: 'column wrap',
		justifyContent: 'flex-start',
		height: '100%',
		width: '100%',
		borderRadius: 6,
		backgroundColor: 'white',
		padding: '24px !important',
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
})

const SecuritySection = (): ReactElement => {
	const classes = useStyle()

	return (
		<Fragment >
			<div className={classes.card}>Security</div>
		</Fragment>
	)
}

export default SecuritySection
