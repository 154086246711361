import { makeStyles } from '@material-ui/core'
import { dark12, dark48, white } from 'lib/colors'

export const useStyle = makeStyles({
	overviewItem: {
		borderRadius: 6,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
		flexDirection: 'column',
		cursor: 'pointer',
	},
	imageRow: {
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		backgroundColor: dark12,
		padding: 12,
		paddingBottom: 0,
	},
	imageContainer: {
		height: 154,
		borderTopLeftRadius: 6,
		borderTopRightRadius: 6,
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		'& img': {
			zIndex: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
	},
	violationsNumber: {
		display: 'block',
		position: 'absolute',
		backgroundColor: white,
		borderRadius: 6,
		padding: 5,
		bottom: 12,
		right: 12,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
	infoRow: {
		borderBottomLeftRadius: 6,
		borderBottomRightRadius: 6,
		backgroundColor: white,
		padding: ' 0 24px',
		height: 58,
	},
	label: {
		color: dark48,
		marginBottom: 2,
	},
	currentReviewer: {
		position: 'absolute',
		left: 12,
		top: 12,
		'& p': {
			textTransform: 'capitalize',
		},
	},
})
