import { makeInternalAPIRequest, makeInternalBackofficeRequest } from 'lib/http/ApiProvider'
import IResponse from 'lib/http/Response'

export interface IScan {
	scanId: string
	scanName: string,
	firstImageId: string,
	firstImageSrc: string,
	violationsCount: number
}

export interface IScanData {
	count: number,
	client_id: string,
	name: string,
}

export interface IScanViolationData{
	name: string,
	violation_id: string
	photo_id: string
	url: string
	destination_image_url: null | string
}

export interface IViolation {
	violation_id: string,
	screenshot: string | null,
	destination_image_url: string | null,
}

export enum Action {
	NotViolating = 'not-violating',
	Violating = 'violating',
}

export enum RejectReason {
	PageNotWorking='pageNotWorking',
	AnotherImage='anotherImage',
	CorrectByline='correctByline',
	Hotlinked='hotlinked',
	OutOfMarket='outOfMarket',
	Spam='spam',
	Other='other',
}

export enum PermissionLevels {
	Admin='admin',
	Agent='agent',
}

export async function getScanBatches(
	offset = 0,
	limit = 30,
	scanNamePart = '',
	signal?: AbortSignal,
): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', '/scans', { offset, limit, scanNamePart }, signal)
}

export async function getNthImageInScan(
	scanId: string,
	offset = 0,
	width = 300,
	signal?: AbortSignal,
): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', `/scans/nth-scan-image/${scanId}`, { offset, width }, signal)
}

export async function getViolationsInScanCount(scanId: string, signal?: AbortSignal): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', `/scans/violations-count/${scanId}`, signal)
}
export async function getImagesByScanIdCount(scanId: string, signal?: AbortSignal): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', `/scans/count-scan-images/${scanId}`, signal)
}

export async function getScanName(scanId: string, signal?: AbortSignal): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', `/scans/name/${scanId}`, signal)
}

export async function getViolationsPerImageInScan(
	scanId: string,
	photoId: string,
	signal?: AbortSignal,
): Promise<IResponse> {
	return makeInternalBackofficeRequest('get', `/scans/violations-on-image/${scanId}`, { photoId }, signal)
}

export async function getScreenshotUrl(violationId: string, width = 650, signal?: AbortSignal): Promise<IResponse> {
	return makeInternalAPIRequest('get', `/violations/preferred_screenshot_url/${width}/${violationId}`, signal)
}

export async function setAction(
	violationIds: Array<string>,
	permissionLevel: PermissionLevels,
	isViolating: boolean,
	rejectReason?: RejectReason,
	signal?: AbortSignal,
): Promise<IResponse> {
	return makeInternalBackofficeRequest('post', '/violations/set-action', {
		violationIds,
		permissionLevel,
		isViolating,
		rejectReason,
		signal,
	})
}

export async function updateScreenshot(
	violationId: string,
	screenshot: string,
	signal?: AbortSignal,
): Promise<IResponse> {
	return makeInternalAPIRequest('post', `/violations/upload_screenshot/${violationId}`, { screenshot }, signal)
}
