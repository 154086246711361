import { IUser } from 'lib/entities/User'

interface IAnalytics {
	track(eventName: string, body: Record<string, unknown>): void
	identify(userId: string, traits: {
		name: string
		email: string
		id: string
		regDate: string
	}): void
}

declare global { interface Window { analytics: IAnalytics; } }

const trackEvent = (eventName: string, body: Record<string, unknown> = {}): void => {
	const location = window.location.pathname
	window.analytics.track(eventName, { ...body, location })
}

const identifyUser = (user: Pick<IUser, 'firstname' | 'email' | 'user_id' | 'reg_date'>): void => {
	window.analytics.identify(`${user.email}`, {
		name: user.firstname,
		email: user.email,
		id: user.user_id,
		regDate: user.reg_date,
	})
}

export { identifyUser }
export default trackEvent
