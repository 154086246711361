import { Icons } from '@copyrightagent/basic-components'
import clsx from 'clsx'
import ImageComponent from 'components/ImageComponent'
import LinkIcon from 'components/LinkIcon'
import { dark72 } from 'lib/colors'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyle } from './style'

interface ICompareDestinationProps {
	image: string
	violationUrl: string
	previewMode: boolean
	addingMode: boolean
	setToFullMode(): void
}

export default function CompareDestination(props: ICompareDestinationProps): ReactElement {
	const { image, violationUrl, previewMode, addingMode, setToFullMode } = props

	const classes = useStyle({ addingMode })
	const { t } = useTranslation('cleaning')

	const switchToFullMode = () => {
		if (previewMode) setToFullMode()
	}

	return (
		<div
			className={clsx(previewMode ? classes.preview : classes.destinationContainer)}
			onClick={switchToFullMode}
		>
			<ImageComponent
				className={classes.imageDestination}
				src={image}
				alt={t('potentialViolation')}
			/>
			{(!previewMode && !addingMode)
				&& <div className={classes.buttonContainer} >
					<div className={classes.iconButtonContainer}>
						<LinkIcon href={violationUrl} targetBlank={true}>
							<Icons.LinkIcon external color={dark72} size="small"/>
						</LinkIcon>
					</div>
				</div>
			}
		</div>
	)
}
