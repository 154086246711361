/* eslint-disable */
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

const locales = {
	en: {
		general: require('./en/general.json'),
		pageNotFound: require('./en/pageNotFound.json'),
		navigation: require('./en/navigation.json'),
		settings: require('./en/settings.json'),
		cleaning: require('./en/cleaning.json'),
	},
}

i18next.use(initReactI18next).init({
	resources: locales,
	lng: 'en',
	fallbackLng: 'en',
	returnEmptyString: true,
	interpolation: {
		escapeValue: false,
	},
	react: {
		wait: true,
	},
})
i18next.t('key', { defaultValue: '' })