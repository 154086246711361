import { createTheme } from '@material-ui/core/styles'
import {
	dark48,
	dark4,
	dark12,
	dark,
	dark24,
	dark72,
} from './colors'

const theme = createTheme({
	props: {
		MuiButtonBase: {
			disableRipple: true,
		},
	},
	typography: {
		fontFamily: ['Open Sans'].join(','),
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1024,
			lg: 1440,
			xl: 1920,
		},
	},
	overrides: {
		MuiLink: {
			root: {
				fontFamily: 'Open Sans',
				fontSize: 14,
				'&:hover': {
					cursor: 'pointer',
				},
			},
			underlineHover: {
				color: dark48,
			},
		},
		MuiTypography: {
			root: {
				color: dark,
			},
			h1: {
				fontSize: 60,
				lineHeight: '82px',
			},
			h2: {
				fontSize: 44,
				lineHeight: '60px',
			},
			h3: {
				fontSize: 40,
				lineHeight: '54px',
			},
			h4: {
				fontSize: 32,
				lineHeight: '44px',
				fontWeight: 600,
			},
			h5: {
				fontSize: 24,
				lineHeight: '33px',
			},
			subtitle1: {
				fontSize: 20,
				lineHeight: '27px',
			},
			body1: {
				fontSize: 16,
				lineHeight: '22px',
			},
			body2: {
				fontSize: 14,
				lineHeight: '20px',
				fontWeight: 600,
			},
			caption: {
				fontSize: 10,
				lineHeight: '14px',
				fontWeight: 600,
			},
		},
		MuiOutlinedInput: {
			root: {
				borderRadius: 6,
				backgroundColor: dark4,
			},
			input: {
				fontSize: 14,
				fontWeight: 600,
				borderRadius: 6,
				'&::placeholder': {
					textOverflow: 'ellipsis !important',
					color: dark48,
					opacity: 1,
				},
			},
			notchedOutline: {
				borderColor: dark12,
			},
		},
		MuiButton: {
			root: {
				borderRadius: 6,
				padding: '10px 12px',
				textTransform: 'none',
				fontSize: 14,
				lineHeight: '20px',
				height: 40,
				minWidth: 100,
				width: 'auto',
				boxShadow: 'none',
				whiteSpace: 'nowrap',
				'&:hover, &:active, &:focus': {
					boxShadow: 'none',
				},
				'&:disabled': {
					backgroundColor: dark4,
					color: dark24,
					border: `1px solid ${dark24}`,
				},
			},
			containedPrimary: {
				'&:hover': {
					boxShadow: 'none',
				},
				'&:focus': {
					boxShadow: 'inset 0 0 2px #44BF93',
				},
			},
			contained: {
				boxShadow: 'none',
				color: dark72,
				backgroundColor: dark12,
				'&:hover, &:active, &:focus': {
					boxShadow: 'none',
				},
				'&:hover': {
					backgroundColor: dark24,
				},
				'&:disabled': {
					backgroundColor: dark4,
				},
			},
			label: {
				fontWeight: 600,
			},
			text: {
				padding: '10px 12px',
			},
		},
		MuiPaper: {
			elevation1: {
				boxShadow: '0 10px 20px 0 rgba(0,0,0,0.10)',
			},
			elevation8: {
				boxShadow: 'box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1)',
			},
		},
		MuiSnackbarContent: {
			root: {
				height: '50px',
				padding: '15px 25px',
				borderRadius: '6px',
				'@media (min-width: 600px)': {
					minWidth: 'auto',
				},
			},
			message: {
				padding: 0,
				fontSize: 14,
				display: 'flex',
				alignItems: 'center',
				fontWeight: 'normal',
				lineHeight: '20px',
			},
		},
		MuiDialog: {
			paper: {
				padding: 32,
				width: 600,
			},
		},
		MuiDialogTitle: {
			root: {
				padding: 0,
			},
		},
		MuiDialogContent: {
			root: {
				padding: '10px 0 35px 0',
				overflowX: 'hidden',
				'@media (max-width: 1440px)': {
					padding: '10px 0 20px 0',
				},
			},
		},
		MuiDialogActions: {
			root: {
				padding: 0,
				justifyContent: 'space-between',
			},
		},
	},
	transitions: {
		create: () => 'all ease-out .2s',
	},
})

export default theme
