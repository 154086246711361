import { makeStyles } from '@material-ui/core'
import { dark12, dark72, red, white } from 'lib/colors'

export const useStyle = makeStyles({
	singleViolation: {
		height: 211,
		borderRadius: 6,
		overflow: 'hidden',
		position: 'relative',
		cursor: 'pointer',
		filter: 'drop-shadow(0px 0px 1px rgba(41, 50, 65, 0.25))',
	},
	singleImage: {
		width: '100%',
		height: '100%',
		zIndex: -2,
		'& img': {
			zIndex: 0,
			width: '100%',
			height: '100%',
			objectFit: 'cover',
		},
		backgroundColor: dark12,
	},
	selectedCover: {
		width: '100%',
		height: '100%',
		position: 'absolute',
		top: 0,
		border: `4px solid ${red}`,
		overflow: 'hidden',
		borderRadius: 6,
		backgroundColor: 'rgba(41, 56, 51, 0.48)',
	},
	selectedIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
		backgroundColor: red,
		width: 20,
		height: 20,
		borderRadius: '0px 0px 0px 6px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	actionContainer: {
		position: 'absolute',
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		width: '100%',
		height: 50,
		bottom: 0,
		background: 'linear-gradient(180deg, rgba(41, 50, 65, 0) 0%, rgba(41, 50, 65, 0.15) 100%)',
		zIndex: 2,
	},
	links: {
		display: 'flex',
		alignSelf: 'flex-end',
		justifyContent: 'center',
		padding: 9,
	},
	linkIcon: {
		backgroundColor: white,
		borderRadius: 6,
		display: 'flex',
		padding: 3,
		zIndex: 200,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
		width: 20,
		height: 20,
		'& svg': {
			width: '100%',
			height: '100%',
		},
	},
	addScreenshot: {
		backgroundColor: white,
		borderRadius: 6,
		display: 'flex',
		padding: '3px 6px',
		alignItems: 'center',
		zIndex: 2,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
		height: 20,
		marginRight: 6,
		'& span': {
			paddingRight: 6,
			color: dark72,

		},
		'& > svg': {
			width: 15,
			height: 15,
		},
	},
	iconButtonContainer: {
		margin: 3,
		zIndex: 200,
	},
})
