import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles({
	root: {
		width: '100%',
		height: '100%',
		minHeight: 200,
		position: 'relative',
		overflow: 'hidden',
		background: 'linear-gradient(90deg, rgba(41, 56, 51, 0) 0.64%, rgba(41, 56, 51, 0.12) 55.09%, rgba(41, 56, 51, 0) 100%), #F6F7F7',
		'&::after': {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			content: '""',
			position: 'absolute',
			animation: 'wave 2s linear infinite',
			display: 'block',
			transform: 'translateX(-100%)',
			background: 'linear-gradient(90deg, transparent, rgb(236, 235, 235), transparent)',
		},
	},
	noScreenshot: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
})
