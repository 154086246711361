import { Typography } from '@copyrightagent/basic-components'
import MainLayout from 'components/MainLayout'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

export default function Dashboard(): ReactElement {
	const { t } = useTranslation('navigation')

	return (
		<MainLayout title={t('dashboard')}>
			<Typography>Blep Blop</Typography>
		</MainLayout>
	)
}
