import { Col, Typography } from '@copyrightagent/basic-components'
import { Container } from '@material-ui/core'
import clsx from 'clsx'
import React, { ReactElement, ReactNode } from 'react'
import { useStyles } from './style'

interface IPageContainerResponsiveProps {
	title?: string
	headerChildren?: ReactNode
	children: ReactNode
	isBodyScrollable?: boolean
}

const PageContainerResponsive = (props: IPageContainerResponsiveProps): ReactElement => {
	const { title, headerChildren, children, isBodyScrollable = false } = props

	const classes = useStyles()

	return (
		<Container maxWidth="xl" className={classes.root} >
			{(title || headerChildren)
			&& <Col className={classes.header}>
				{title && <Typography variant='h4' className={classes.title}>{title}</Typography>}
				{headerChildren && headerChildren}
			</Col>
			}
			<div className={clsx(classes.body, isBodyScrollable && classes.scrollable)}>
				{children}
			</div>
		</Container>
	)
}
export default PageContainerResponsive
