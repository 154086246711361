import { makeStyles } from '@material-ui/core'

export const useStyle = makeStyles({
	linkIcon: {
		backgroundColor: '#fff',
		borderRadius: 6,
		display: 'flex',
		padding: 3,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
		width: 20,
		height: 20,
		'& svg': {
			width: '100%',
			height: '100%',
		},
	},
})
