export interface IClient {
	hub_id: string
	name: string
	bucket_prefix: string
}

export function createClient(props: Partial<IClient> = {}): IClient {
	const resolvedProperties = props || {}

	return {
		hub_id: resolvedProperties.hub_id || '',
		name: resolvedProperties.name || '',
		bucket_prefix: resolvedProperties.bucket_prefix || '',
	}
}
