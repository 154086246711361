import { makeStyles } from '@material-ui/core'
import { dark48 } from 'lib/colors'

export const useStyles = makeStyles(theme => ({
	root: {
		height: '100%',
		width: '100%',
	},
	title: {
		marginBottom: 10,
	},
	subTitle: {
		color: dark48,
	},
}))
