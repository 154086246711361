import { makeStyles } from '@material-ui/styles'
import {
	grayBackground,
	dark48,
	white,
} from 'lib/colors'

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexFlow: 'row wrap',
		justifyContent: 'flex-start',
		height: '100%',
		width: '100%',
		borderRadius: 6,
		marginTop: 0,
		backgroundColor: 'white',
		padding: '24px !important',
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
	title: {
		paddingBottom: 12,
		height: 33,
	},
	tableRoot: {
		width: '100%',
		height: '90%',
		boxShadow: 'none',
	},
	tableContainer: {
		height: '100%',
	},
	table: {
		borderSpacing: '0 10px',
		borderCollapse: 'separate',
	},
	tableRow: {
		height: 60,
		borderRadius: 6,
		backgroundColor: grayBackground,
		borderBottom: `10px solid ${white}`,
		'& > td:first-of-type': {
			borderTopLeftRadius: 10,
			borderBottomLeftRadius: 10,
		},
		'& > td:last-of-type': {
			borderTopRightRadius: 10,
			borderBottomRightRadius: 10,
		},
	},
	tableHeaderRow: {
		height: 30,
	},
	tableHeaderCell: {
		color: dark48,
		fontWeight: 600,
		backgroundColor: 'white',
	},
	tableCell: {
		height: 60,
		fontWeight: 600,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
})
