const green = 'rgba(72, 210, 160, 1)'
const green12 = 'rgba(72, 210, 160, 0.12)'
const green24 = 'rgba(72, 210, 160, 0.24)'
const green48 = 'rgba(72, 210, 160, 0.48)'
const green150 = 'rgba(17, 173, 117, 1)'
const dark = 'rgba(41, 56, 51, 1)'
const dark4 = 'rgba(246, 247, 247, 1)'
const dark12 = 'rgba(229, 231, 230, 1)'
const dark24 = 'rgba(204, 207, 206, 1)'
const dark48 = 'rgba(152, 159, 157, 1)'
const dark72 = 'rgba(101, 112, 108, 1)'
const red = 'rgba(206, 80, 94, 1)'
const red12 = 'rgba(206, 80, 94, 0.12)'
const red150 = 'rgba(184, 53, 68, 1)'
const yellow = 'rgba(248, 197, 28, 1)'
const yellow12 = 'rgba(248, 197, 28, 0.12)'
const yellow48 = 'rgba(248, 197, 28, 0.48)'
const yellow150 = 'rgba(224, 184, 49, 1)'
const orange = 'rgba(248, 160, 28, 1)'
const blue = 'rgba(42, 167, 220, 1)'
const blue12 = 'rgba(42, 167, 220, 0.12)'
const grayBackground = 'rgba(246, 247, 247, 1)'
const white = 'rgba(255, 255, 255, 1)'

export {
	green,
	green12,
	green24,
	green48,
	green150,
	dark,
	dark4,
	dark12,
	dark24,
	dark48,
	dark72,
	red,
	red12,
	red150,
	yellow,
	yellow12,
	yellow48,
	yellow150,
	orange,
	blue,
	blue12,
	grayBackground,
	white,
}
