import { makeStyles } from '@material-ui/core'
import { dark, dark4, dark72, green, green12, green24 } from 'lib/colors'

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		height: '100%',
	},
	leftColumn: {
		display: 'flex',
		flexDirection: 'column',
		width: 300,
		marginRight: 12,
	},
	rightColumn: {
		display: 'flex',
		flexDirection: 'column',
	},
	card: {
		display: 'flex',
		flexFlow: 'column wrap',
		justifyContent: 'flex-start',
		height: '100%',
		borderRadius: 6,
		backgroundColor: 'white',
		padding: 20,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
	h5: {
		color: dark,
		fontWeight: 600,
		paddingBottom: 12,
	},
	listItem: {
		height: 40,
		borderRadius: 6,
		marginTop: 12,
		'&:hover': {
			backgroundColor: dark4,
			'& > div > span': {
				color: dark,
			},
		},
	},
	listItemText: {
		'& > span': {
			color: dark72,
			cursor: 'pointer',
			fontWeight: 600,
			fontSize: 14,
		},
	},
	selectedListItem: {
		backgroundColor: green12,
		'& > div > span, & > div > span:hover': {
			color: `${green} !important`,
		},
		'&:hover': {
			backgroundColor: green24,
		},
	},
})
