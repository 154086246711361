import React, { useState, useEffect, ReactElement } from 'react'
import { Icons, Row } from '@copyrightagent/basic-components'
import { dark72, white } from 'lib/colors'
import ImageComponent from 'components/ImageComponent'
import { useTranslation } from 'react-i18next'
import ButtonLink from 'components/ButtonLink'
import LinkIcon from 'components/LinkIcon'
import { getScreenshotUrl } from 'routes/Private/Cleaning/api'
import { useFetchWithAbortSignal } from 'hooks/fetch'
import { useStyle } from './style'
import ComparingModal from '../ComparingModal'

interface IViolationProps {
	originalImage: string
	destinationImage: string
	violationUrl: string
	isSelected: boolean
	id: string
	onSelect(arg: string): void
	onDeselect(arg: string): void
	onUpdateViolations(): void
}

export default function Violation(props: IViolationProps): ReactElement {
	const {
		originalImage,
		destinationImage,
		violationUrl,
		isSelected,
		id,
		onSelect,
		onDeselect,
		onUpdateViolations,
	} = props

	const classes = useStyle()
	const { t } = useTranslation('cleaning')

	const [selected, setSelected] = useState(false)
	const [comparisonModalOpen, setComparisonModalOpen] = useState<boolean>(false)
	const [addingScreenshot, setAddingScreenshot] = useState<boolean>(false)
	const [screenshot, setScreenshot] = useState<string | undefined>()
	const [destImage, setDestImage] = useState<string>('')

	// Set destination image
	useEffect(() => {
		setDestImage(destinationImage)
	}, [destinationImage])

	// Sets screenshot
	useFetchWithAbortSignal(async signal => {
		const responseGetScreenshotUrl = await getScreenshotUrl(id, 650, signal)
		if (responseGetScreenshotUrl.status === 499) return
		if (responseGetScreenshotUrl.status === 404) return setScreenshot('')
		return setScreenshot(responseGetScreenshotUrl.data)
	}, [id])

	// Sets selected violations
	useEffect(() => {
		setSelected(isSelected)
	}, [isSelected])

	const toggleSelected = () => {
		setSelected(prevState => !prevState)
		if (selected) onDeselect(id)
		if (!selected) onSelect(id)
	}

	const openComparisonModal = () => {
		setComparisonModalOpen(true)
	}
	const closeComparisonModal = () => {
		setAddingScreenshot(false)
		setComparisonModalOpen(false)
	}

	const showComparison = (addingMode: boolean) => {
		openComparisonModal()
		setAddingScreenshot(addingMode)
	}

	return (
		<div className={classes.singleViolation} id={id}>
			<Row onClick={toggleSelected} className={classes.singleImage}>
				<ImageComponent
					src={destImage || screenshot || ''}
					alt={`${t('violationImage')}`}
					srcAlternative={screenshot}
					onError={() => setDestImage('')}
				/>
			</Row>
			{ selected
				&& <>
					<div onClick={toggleSelected} className={classes.selectedCover}/>
					<div className={classes.selectedIcon}><Icons.CheckIcon color={white} size="small"/></div>
				</>
			}
			<div className={classes.actionContainer} onClick={toggleSelected}>
				<div className={classes.links} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
					{screenshot === ''
					&& <div className={classes.iconButtonContainer} >
						<ButtonLink text={t('addScreenshot')} onClick={() => showComparison(true)}>
							<Icons.EditIcon color={dark72} size="small"/>
						</ButtonLink>
					</div>
					}
					<div className={classes.iconButtonContainer} >
						<LinkIcon href={violationUrl} targetBlank={true} >
							<Icons.LinkIcon external color={dark72} size="small"/>
						</LinkIcon>
					</div>
					<div className={classes.iconButtonContainer} >
						<ButtonLink onClick={() => showComparison(false)}>
							<Icons.CompareIcon color={dark72} size="small"/>
						</ButtonLink>
					</div>
				</div>
			</div>
			{ (screenshot !== undefined) && comparisonModalOpen
				&& <ComparingModal
					originalImage={originalImage}
					destinationImage={destImage}
					violationId={id}
					screenshot={screenshot}
					violationUrl={violationUrl}
					isOpen={comparisonModalOpen}
					inAddingMode={addingScreenshot}
					onDeselect={onDeselect}
					onSetClose={closeComparisonModal}
					onScreenshotChange={setScreenshot}
					onUpdateViolations={onUpdateViolations}
				/>
			}
		</div>
	)
}
