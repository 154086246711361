import { getAuth, signOut } from 'firebase/auth'
import { sleep } from 'lib/utils'
import { createClient, IClient } from 'lib/entities/Client'
import { IUser } from 'lib/entities/User'
import { RouteUrls } from 'routes/RouteUrls'
import getStore from './store'
import { CLOSE_SNACK, ISnackbarState, OPEN_SNACK } from './snackbar'
import { LOG_OUT, SET_TOKEN } from './auth'

const { store } = getStore()

export function getCurrentUser(): IUser {
	return store.getState().AuthState.currentUser
}

export function getClients(): IClient[] {
	return store.getState().AuthState.currentUser.hubs
}

export function getSelectedClient(): IClient {
	return createClient(store.getState().AuthState.selectedClient)
}

export function getSessionToken(): string {
	return store.getState().AuthState.token
}

export const setSessionToken = (): void => {
	const auth = getAuth()
	const { dispatch } = store
	const user = auth.currentUser
	if (user) {
		user.getIdToken(true).then(idToken => {
			if (idToken !== getSessionToken()) dispatch({ type: SET_TOKEN, token: idToken })
		})
	}
}

export async function toast(
	message = '',
	variant: ISnackbarState['variant'],
): Promise<void> {
	const { dispatch } = store

	dispatch({ type: OPEN_SNACK, message, variant })
	await sleep(7000)
	dispatch({ type: CLOSE_SNACK })
}

export const logout = (): void => {
	const { dispatch } = store
	const auth = getAuth()
	signOut(auth)
	dispatch({ type: LOG_OUT })
	if (window.location.href.indexOf('login') === -1) window.location.pathname = RouteUrls.login
}
