import { makeStyles } from '@material-ui/styles'

export const useStyle = makeStyles({
	scrollableDiv: {
		height: '100%',
		width: '100%',
		paddingBottom: 98,
		overflow: 'auto',
	},
	grid: {
		display: 'grid',
		gap: 8,
		gridTemplateColumns: 'repeat(auto-fill, minmax(186px, 1fr))',
	},
})
