import { makeStyles } from '@material-ui/core'
import { dark, dark12, dark48, dark72, white } from 'lib/colors'
import { loaderCentering } from 'lib/utils'

export const useStyle = makeStyles({
	actionBannerContainer: {
		position: 'absolute',
		left: 0,
		bottom: 0,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: 24,
	},
	actionBanner: {
		height: 72,
		minWidth: 548,
		backgroundColor: '#fff',
		borderRadius: 6,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
	},
	reason: {
		color: dark,
	},
	numberOfViolations: {
		minWidth: 72,
		height: 72,
		borderRadius: 6,
		backgroundColor: dark,
		alignSelf: 'flex-start',
		'& h4': {
			color: white,
		},
	},
	actionLabel: {
		fontWeight: 600,
		marginLeft: 24,
	},
	actionCalls: {
		marginLeft: 'auto',
		padding: 20,
		color: dark72,
	},
	selectToggle: {
		cursor: 'pointer',
		color: dark48,
		marginRight: 8,
		width: 100,
	},
	submitButton: {
		padding: 10,
	},
	rejectButton: {
		minHeight: 48,
	},
	loader: loaderCentering,
	confirmModalNumber: {
		width: '100%',
		height: 96,
		backgroundColor: dark12,
		borderRadius: 6,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& h5': {
			fontWeight: 600,
		},
	},
	confirmModalCloseButton: {
		color: dark72,
		marginRight: 8,
	},
	confirmModalFooter: {
		color: dark72,
		display: 'flex',
		justifyContent: 'flex-end',
	},
})
