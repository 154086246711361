import { Col, Icons, ImageLabel, Row, Typography } from '@copyrightagent/basic-components'
import { Link } from '@material-ui/core'
import clsx from 'clsx'
import ImageComponent from 'components/ImageComponent'
import { dark } from 'lib/colors'
import { IImageData } from 'lib/entities/Image'
import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyle } from './style'

interface IOriginalImageProps {
	image: IImageData
}

export default function OriginalImage(props: IOriginalImageProps): ReactElement {
	const { image } = props

	const classes = useStyle()
	const { t } = useTranslation('cleaning')

	return (
		<>
			<Row className={classes.imageContainer}>
				<ImageComponent
					src={image.url || ''}
					alt={t('originalImage')}
				/>
				<ImageLabel text={t('originalImage')} type='brand' />
			</Row>
			<Col className={classes.imageInfo}>
				<Col className={classes.imageInfoTop}>
					<Row className={classes.imageInfoRow}>
						<Typography variant='body2' className={classes.imageInfoLabel}>
							{t('image')}:
						</Typography>
						<Link href={image.url} target="_blank">
							<Typography variant='body2'>
								{image.name} <Icons.LinkIcon external color={dark}/>
							</Typography>
						</Link>
					</Row>
					<Row>
						<Typography variant='body2' className={clsx(classes.imageInfoLabel, classes.lightText)} >
							{t('photographer')}:
						</Typography>
						<Typography variant='body2' className={classes.lightText} >
							{image.photographer_name}
						</Typography>
					</Row>
				</Col>
				<Row>
					<Typography variant='body2' className={clsx(classes.imageInfoLabel, classes.lightText)} >
						{t('vendor')}:
					</Typography>
					<Typography variant='body2' className={classes.lightText}>
						{image.supplier}
					</Typography>
				</Row>
			</Col>
		</>
	)
}
