import { CopyrightAgentLoader, FilterSearch, IFilterSearchLocales, PopoverRef, Row } from '@copyrightagent/basic-components'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NoMoreToShow from 'components/NoMoreToShow'
import { loaderCentering, parseQueryParams, qsArrayFormat } from 'lib/utils'
import MainLayout from 'components/MainLayout'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import trackEvent from 'hooks/useAnalytics'
import { useFetchWithAbortSignal } from 'hooks/fetch'
import { IFilters } from '../../../lib/types'
import { getScanBatches, IScanData } from './api'
import { useStyle } from './style'
import OverviewListItem from '../../../components/OverviewListItem'

export const getDefaultFilters = (): IFilters => ({
	searchTerm: '',
})

export default function Cleaning(): ReactElement {
	const { t } = useTranslation()
	const classes = useStyle()

	const history = useHistory()
	const location = useLocation()
	const popoverRef = useRef<PopoverRef>(null)

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [scans, setScans] = useState<IScanData[]>([])
	const [hasMore, setHasMore] = useState<boolean>(false)
	const [offset, setOffset] = useState<number>(0)
	const [filters, setFilters] = useState<IFilters>(
		() => parseQueryParams(getDefaultFilters(), location.search),
	)

	const perPage = 30

	const handleSetScans = (currentOffset: number, data: IScanData[]) => {
		if (currentOffset === 0) return setScans(data)
		setScans(prevScanBatches => [...prevScanBatches, ...data])
	}

	// Sets scan batches
	useFetchWithAbortSignal(async signal => {
		if (offset === 0) setIsLoading(true)
		const responseGetScanByScanName = await getScanBatches(
			offset,
			perPage,
			filters.searchTerm,
			signal,
		)
		if (responseGetScanByScanName.status === 499) return

		if (responseGetScanByScanName.data.length > 0) setHasMore(true)
		else setHasMore(false)

		handleSetScans(offset, responseGetScanByScanName.data)
		setIsLoading(false)
	}, [filters.searchTerm, offset])

	const onApplyFilters = useCallback(() => {
		const qString = qs.stringify(filters, qsArrayFormat)
		history.replace(`${location.pathname}?${qString}`)
	}, [filters, history, location.pathname])

	const getFilterByURLSearchPart = useCallback(
		(URLSeachPart: string) => parseQueryParams(getDefaultFilters(), URLSeachPart), [],
	)

	// Update the current filters when the url params change
	useEffect(() => {
		const parsedFilters = getFilterByURLSearchPart(location.search)
		setFilters(parsedFilters)
	}, [getFilterByURLSearchPart, location.search])

	const handleClose = useCallback(() => {
		if (!popoverRef || !popoverRef.current) return
		popoverRef.current.closePopover()
	}, [popoverRef])

	const applyFiltering = useCallback(() => {
		handleClose()
		onApplyFilters()
	}, [onApplyFilters, handleClose])

	const onSearchSubmit = useCallback((searchTerm: string): void => {
		trackEvent('Searched a violation', {
			query: searchTerm,
		})
		setOffset(0)
	}, [])

	const locales = useMemo((): IFilterSearchLocales => ({
		submitText: t('general:search'),
		placeholder: t('cleaning:searchPlaceholder'),
	}), [t])

	const searchBar = useMemo(() => (<Row className={classes.header}>
		<FilterSearch<IFilters>
			filterSearchKey='searchTerm'
			applyFiltering={applyFiltering}
			onSearchSubmit={onSearchSubmit}
			locales={locales}
			getFilterByURLSearchPart={getFilterByURLSearchPart}
			location={location}
			history={history}
		/>
	</Row>),
	[applyFiltering, classes.header, getFilterByURLSearchPart, history, locales, location, onSearchSubmit])

	const renderImagesList = useMemo(() => {
		if (scans && scans.length > 0) {
			return (
				<InfiniteScroll
					dataLength={scans.length}
					next={() => setOffset(scans.length)}
					hasMore={hasMore}
					scrollableTarget="scrollableDiv"
					loader={null}
				>
					<div className={classes.grid}>
						{
							scans.map((scan, index) => <div key={index}>
								<OverviewListItem scan={scan} />
							</div>)
						}
					</div>
				</InfiniteScroll>
			)
		}
		return <NoMoreToShow/>
	}, [classes.grid, hasMore, scans])

	return (
		<MainLayout title={t('navigation:cleaning')} headerChildren={searchBar}>
			{isLoading
				? <CopyrightAgentLoader style={loaderCentering}/>
				: renderImagesList
			}
		</MainLayout>
	)
}
