import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import i18n from 'i18next'
import { logout, toast } from 'store/utils'
import { RouteUrls } from 'routes/RouteUrls'

const {
	REACT_APP_SITE_URL,
	REACT_APP_FIREBASE_SITE_NAME,
	REACT_APP_FIREBASE_MESSAGING_ID,
	REACT_APP_FIREBASE_APP_ID,
	REACT_APP_FIREBASE_API_KEY,
} = process.env

const firebaseConfig = {
	apiKey: REACT_APP_FIREBASE_API_KEY,
	authDomain: `${REACT_APP_FIREBASE_SITE_NAME}.firebaseapp.com`,
	databaseURL: `https://${REACT_APP_FIREBASE_SITE_NAME}.firebaseio.com`,
	projectId: REACT_APP_FIREBASE_SITE_NAME,
	storageBucket: `${REACT_APP_FIREBASE_SITE_NAME}.appspot.com`,
	messagingSenderId: REACT_APP_FIREBASE_MESSAGING_ID,
	appId: REACT_APP_FIREBASE_APP_ID,
}

initializeApp(firebaseConfig)

const actionCodeSettings = {
	url: `${REACT_APP_SITE_URL}${RouteUrls.loggingIn}?backoffice=true`,
	handleCodeInApp: true,
}

const auth = getAuth()

const loginFailed = async (errorMessage: string) => {
	await toast(i18n.t(errorMessage), 'warning')
	return logout()
}

export const attemptLogin = (email: string): void => {
	sendSignInLinkToEmail(auth, email, actionCodeSettings)
		.then(() => window.localStorage.setItem('emailForSignIn', email))
}

export const checkIfLoggingIn = async (): Promise<boolean | void> => {
	if (!isSignInWithEmailLink(auth, window.location.href)) return false

	const email = window.localStorage.getItem('emailForSignIn')
	if (!email) return loginFailed('login/differentDevice')

	signInWithEmailLink(auth, email, window.location.href).then(() => window.localStorage.removeItem('emailForSignIn'))
		.catch(() => {
			loginFailed('login/fail')
		})
}
