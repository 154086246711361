import { createClient, IClient } from './Client'

export interface IUser {
	user_id: string
	email: string
	firstname: string
	lastname: string
	permission_level: string
	reg_date: string
	isphotographer: boolean
	hubs: IClient[]
	token: string
}

export const createUser = (props: Partial<IUser & { auth_token: string }> = {}): IUser => (
	{
		email: props.email || '',
		user_id: props.user_id || '',
		firstname: props.firstname || '',
		lastname: props.lastname || '',
		permission_level: props.permission_level || '',
		reg_date: props.reg_date || '',
		isphotographer: props.isphotographer || false,
		token: props.auth_token || props.token || '',
		hubs: Array.isArray(props.hubs) ? props.hubs.map(hub => createClient(hub)) : [],
	}
)
