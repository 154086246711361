import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteUrls } from 'routes/RouteUrls'
import SecuritySection from './Security'
import UserDetailsSection from './UserDetails'
import UsersSection from './Users'

export interface IMenuItems {
	path: RouteUrls,
	title: string,
	component: () => ReactElement
}

export const settingsMenuItems = (): Array<IMenuItems> => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { t } = useTranslation('settings')

	const menuItems = [
		{
			path: RouteUrls.users,
			title: t('menuItems/link/users'),
			component: UsersSection,
		},
		{
			path: RouteUrls.security,
			title: t('menuItems/link/security'),
			component: SecuritySection,
		},
		{
			path: RouteUrls.userDetails,
			title: t('menuItems/link/userDetails'),
			component: UserDetailsSection,
		},
	]

	return menuItems
}
export default settingsMenuItems
