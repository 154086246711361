import { makeStyles } from '@material-ui/styles'

import { Theme } from '@material-ui/core'
import {
	dark48,
} from 'lib/colors'

export default makeStyles((theme: Theme) => ({
	page: {
		display: 'flex',
		height: '100vh',
		justifyContent: 'center',
		alignItems: 'center',
	},
	logo: {
		marginTop: 48,
		marginLeft: 48,
		position: 'absolute',
	},
	terms: {
		color: dark48,
		fontWeight: 400,
		fontSize: 14,
	},
	termsLink: {
		color: 'black',
		fontSize: 14,
		fontWeight: 400,
	},
	bottom: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: 750,
		textAlign: 'center',
		position: 'absolute',
		bottom: 36,
	},
}))
