import { Col, Typography } from '@copyrightagent/basic-components'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './style'

export default function NoMoreToShow():ReactElement {
	const classes = useStyles()
	const { t } = useTranslation('cleaning')

	return (
		<Col className={classes.root} align='center'>
			<Typography variant='subtitle1' className={classes.title}>
				{t('hooray')}
			</Typography>
			<Typography variant='body2' className={classes.subTitle}>
				{t('noMoreViolations')}
			</Typography>
		</Col>
	)
}
