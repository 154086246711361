import React, { Fragment, ReactElement, useEffect } from 'react'
import {
	Menu,
	MenuItem,
	Table,
	TableRow,
	TableBody,
	TableCell,
	TableHead,
	TableSortLabel,
	TableContainer,
} from '@material-ui/core'
import { Icons } from '@copyrightagent/basic-components'
import { useStyles } from './style'

interface IColumn {
	id: 'fullName' | 'email' | 'permission_level' | 'user_id'
	label: string
	minWidth?: number
}

interface IRows {
	user_id: string
	fullName: string
	email: string
	permission_level: string
}

type TOrder = 'asc' | 'desc';

interface ITableHeadProps {
	classes: ReturnType<typeof useStyles>
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IRows) => void
	order: TOrder
	columns: IColumn[]
	orderBy: string
	rowCount: number
}

const TableHeader = (props: ITableHeadProps): ReactElement => {
	const { classes, order, orderBy, onRequestSort, columns } = props

	const createSortHandler = (property: keyof IRows) => (event: React.MouseEvent<unknown>) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			<TableRow className={classes.tableHeaderRow}>
				{columns.map((column: IColumn) => (
					<TableCell
						key={column.id}
						className={classes.tableHeaderCell}
						style={{ minWidth: column.minWidth }}
					>
						<TableSortLabel
							active={orderBy === column.id}
							direction={orderBy === column.id ? order : 'asc'}
							onClick={createSortHandler(column.id)}
						>
							{column.label}
							{orderBy === column.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell className={classes.tableHeaderCell} />
			</TableRow>
		</TableHead>
	)
}

interface IUserSectionProps {
	columns: IColumn[]
	rows: IRows[]
	onDeleteUser(id: string): void
	onEditUser(id: string): void
}

const SortableTable = (props: IUserSectionProps): ReactElement => {
	const { rows, columns, onDeleteUser, onEditUser } = props

	const classes = useStyles()
	const [order, setOrder] = React.useState<TOrder>('asc')
	const [activeUser, setActiveUser] = React.useState('')
	const [orderBy, setOrderBy] = React.useState<keyof IRows>('fullName')
	const [anchorEl, setAnchorEl] = React.useState<null | SVGSVGElement>(null)

	const handleUserClick = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
		setActiveUser(id)
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleDeleteUser = () => {
		onDeleteUser(activeUser)
		handleClose()
	}

	const handleEditUser = () => {
		onEditUser(activeUser)
		handleClose()
	}

	const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IRows) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	return (
		<TableContainer className={classes.tableContainer}>
			<Table stickyHeader className={classes.table}>
				<TableHeader
					classes={classes}
					order={order}
					orderBy={orderBy}
					columns={columns}
					onRequestSort={handleRequestSort}
					rowCount={rows.length}
				/>
				<TableBody>
					{rows.map((row, index) => (
						<TableRow
							hover
							role="checkbox"
							tabIndex={-1}
							key={index}
							className={classes.tableRow}
						>
							{columns.map((column: IColumn) => {
								if (column.id !== 'user_id') {
									const value = row[column.id]
									return (
										<TableCell key={column.id} className={classes.tableCell}>
											{value}
										</TableCell>
									)
								}
								return (<Fragment key={column.id} />)
							})}
							<TableCell className={classes.tableCell}>
								<Icons.DotsIcon
									direction='horizontal'
									color='black'
									onClick={(e: React.MouseEvent<SVGSVGElement>) => handleUserClick(e, row.user_id)}
								/>
							</TableCell>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem onClick={handleEditUser}>Edit</MenuItem>
								<MenuItem onClick={handleDeleteUser}>Remove</MenuItem>
							</Menu>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}

export default SortableTable
