/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getAuth, onAuthStateChanged, Unsubscribe } from 'firebase/auth'
import { SET_PROFILE, SET_TOKEN } from 'store/auth'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import { identifyUser } from 'hooks/useAnalytics'
import { logout } from 'store/utils'
import { me } from './api'

export default (): void => {
	const router = useRouter()
	const auth = getAuth()
	const dispatch = useDispatch()

	const setProfile = async (): Promise<void> => {
		const userResponse = await me()

		if (!userResponse.isSuccessful) logout()

		identifyUser({
			email: userResponse.data.email,
			firstname: userResponse.data.firstname,
			user_id: userResponse.data.user_id,
			reg_date: new Date().toISOString(),
		})

		dispatch({ type: SET_PROFILE, data: userResponse.data })

		const user = userResponse.data
		if (!user.user_id) return logout()

		if (router.location.pathname.startsWith('/loggingIn')) router.history.push(RouteUrls.dashboard)
	}

	useEffect(() => {
		let unsubscribe: Unsubscribe
		const checkUser = async () => {
			let throttle = true
			unsubscribe = onAuthStateChanged(auth, user => {
				if (router.location.pathname.includes(RouteUrls.loggingIn) && !user) return
				if (!user) return logout()
				if (throttle) {
					throttle = false
					user.getIdToken(true).then(idToken => {
						dispatch({ type: SET_TOKEN, token: idToken })
						setProfile()
					})
				}
			})
		}
		checkUser()
		return function cleanup() {
			unsubscribe()
		}
	}, [])
}
