import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Icons, NavigationMenuBase } from '@copyrightagent/basic-components'
import { RouteUrls } from 'routes/RouteUrls'
import { SystemImages } from 'assets'
import { Divider } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useRouter } from 'routes/CustomBrowserRouter'
import { LOG_OUT } from 'store/auth'

export default function Navigation(): ReactElement {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const router = useRouter()

	const handleLogout = () => {
		dispatch({ type: LOG_OUT })
		router.history.push(RouteUrls.login)
	}

	const actions = [
		{
			icon: Icons.LogoutIcon,
			label: t('navigation:logout') || 'logout',
			route: RouteUrls.logout,
			onClick: handleLogout,
		},
	]

	const defaultClient = {
		logosrc: SystemImages.logoDark,
		name: 'Copyright Agent',
	}

	const mainNavRoutes = [
		{
			icon: Icons.DashboardIcon,
			label: t('navigation:dashboard'),
			route: RouteUrls.dashboard,
		},
		{
			icon: Icons.CleaningIcon,
			label: t('navigation:cleaning'),
			route: RouteUrls.cleaning,
		},
		{
			icon: Icons.DatamappingIcon,
			label: t('navigation:dataEnrichment'),
			route: RouteUrls.dataEnrichment,
		},
		{
			icon: Icons.InitialReviewIcon,
			label: t('navigation:initialReview'),
			route: RouteUrls.initialReview,
		},
		{
			icon: Icons.AdminReviewIcon,
			label: t('navigation:adminReview'),
			route: RouteUrls.adminReview,
		},
	]

	const bottomNavRoutes = [
		{
			icon: Icons.NotificationsIcon,
			label: t('navigation:notifications'),
			route: RouteUrls.notifications,
		},
		{
			icon: Icons.SearchIcon,
			label: t('navigation:search'),
			route: RouteUrls.search,

		},
		{
			icon: Icons.SettingsIcon,
			label: t('navigation:settings'),
			route: RouteUrls.settings,

		},
	]

	return (
		<NavigationMenuBase>
			<NavigationMenuBase.AccountSwitcher
				defaultClient={defaultClient}
				actions={actions}
			/>
			<Divider />
			<NavigationMenuBase.NavigationItems items={mainNavRoutes} />
			<NavigationMenuBase.NavigationItems items={bottomNavRoutes} stickToBottom />
		</NavigationMenuBase>
	)
}
