import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { useStyle } from './style'

export interface IStatusIconProps {
	state: 'open' | 'closed' | 'submitted',
	className: string
}

export default function StatusIcon(props: IStatusIconProps): ReactElement {
	const { state = 'open', className } = props

	const classes = useStyle()
	const { t } = useTranslation()

	return (
		<>
			{
				state === 'open'
				&& <div
					className={clsx(classes.open, className)}
					aria-label={t('cleaning:violationStatus', { state })}
				/>
			}
		</>
	)
}
