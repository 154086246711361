import { makeStyles } from '@material-ui/styles'
import { white } from 'lib/colors'

export const useStyle = makeStyles({
	loadMoreWrap: {
		display: 'flex',
		justifyContent: 'center',
	},
	header: {
		backgroundColor: white,
		padding: 12,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
		borderRadius: 6,
		marginBottom: 24,
		width: '100%',
	},
	grid: {
		display: 'grid',
		gap: 16,
		gridTemplateColumns: 'repeat(auto-fill, minmax(310px, 1fr))',
	},
})
