import { makeStyles } from '@material-ui/core'
import { dark4 } from 'lib/colors'

export const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '100%',
		backgroundColor: dark4,
		display: 'flex',
		overflow: 'auto',
	},
})
