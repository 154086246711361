import { CSSProperties } from '@material-ui/styles'
import queryString from 'query-string'
import { IFilters } from './types'
import { KeysWhereValueMatches } from './utility-types'

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const sleep = (time: number): Promise<void> => new Promise(
	resolve => setTimeout(resolve, time),
)

export const loaderCentering: CSSProperties = {
	position: 'absolute',
	width: 50,
	height: 50,
	top: 'calc(50% - 25px)',
	left: 'calc(50% - 25px)',
}

const transformValue = (value: string | null | (string | null)[]): string | string[] => {
	if (value === null) return ''
	if (Array.isArray(value)) return value.map(v => v || '')
	return value
}

type ArrayFilterKeys = KeysWhereValueMatches<IFilters, string[]>
type StringFilterKeys = KeysWhereValueMatches<IFilters, string>

export const parseQueryParams = (
	initialFilters: IFilters,
	search: string,
): IFilters => {
	const parsedQueryParams = queryString.parse(search, { arrayFormat: 'comma' })

	const parsedFilters = Object.entries(initialFilters).reduce((acc, [key]) => {
		const value = parsedQueryParams[key]
		const transformedValue = transformValue(value)
		const valueIsArray = Array.isArray(transformedValue)
		const arrayValue = valueIsArray ? transformedValue : [transformedValue]
		const singleValue = valueIsArray ? transformedValue[0] : transformedValue

		if (parsedQueryParams[key]) {
			if (Array.isArray(initialFilters[key as keyof IFilters])) {
				// we have to cast the key here because TS doesn't know we're only working with keys that hold arrays
				acc[key as ArrayFilterKeys] = arrayValue as IFilters[ArrayFilterKeys]
			} else {
				// same here, except now it's going to be only keys that hold strings as values
				acc[key as StringFilterKeys] = singleValue as IFilters[StringFilterKeys]
			}
		}

		return acc
	}, initialFilters)

	return parsedFilters
}

export const qsArrayFormat: queryString.StringifyOptions = {
	skipEmptyString: true,
	skipNull: true,
	arrayFormat: 'comma',
}
