import { Typography } from '@copyrightagent/basic-components'
import React, { ReactElement } from 'react'
import { useStyle } from './style'

export interface IButtonLinkProps {
	text?: string,
	onClick(): void,
	children: ReactElement
}

export default function ButtonLink(props: IButtonLinkProps): ReactElement {
	const { text = '', onClick, children } = props

	const classes = useStyle({ text })

	return (
		<div className={classes.buttonIcon} onClick={onClick}>
			{text
				&& <Typography variant='body2'>
					{text}
				</Typography>
			}
			{children}
		</div>
	)
}
