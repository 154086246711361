import { Row, Col, ImageLabel, SecondaryButton, Modal, ModalSection, PrimaryButton } from '@copyrightagent/basic-components'
import clsx from 'clsx'
import CompareDestination from 'components/CompareDestination'
import CompareScreenshot from 'components/CompareScreenshot'
import ImageComponent from 'components/ImageComponent'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PermissionLevels, setAction } from 'routes/Private/Cleaning/api'
import AddScreenshot from '../AddScreenshot'
import { useStyle } from './style'

interface IComparingModalProps {
	originalImage: string
	destinationImage: string
	violationId: string
	screenshot: string
	violationUrl:string
	isOpen: boolean
	inAddingMode: boolean
	onDeselect(arg: string): void
	onSetClose(): void
	onScreenshotChange(arg: string): void
	onUpdateViolations(): void
}

export default function ComparingModal(props: IComparingModalProps): ReactElement {
	const {
		originalImage,
		destinationImage,
		violationId,
		screenshot,
		violationUrl,
		isOpen,
		onDeselect,
		onSetClose,
		onScreenshotChange,
		onUpdateViolations,
		inAddingMode,
	} = props

	const { t } = useTranslation('cleaning')
	const classes = useStyle()

	const [violationImage, setViolationImage] = useState<string>()
	const [isScreenshotInPreview, setIsScreenshotInPreview] = useState<boolean>(true)
	const [addingMode, setAddingMode] = useState<boolean>(inAddingMode)
	const [updatedScreenshot, setUpdatedScreenshot] = useState<string>()

	// Sets violation image
	useEffect(() => {
		setViolationImage(screenshot)
	}, [screenshot])

	// Sets adding mode
	useEffect(() => {
		setAddingMode(inAddingMode)
	}, [inAddingMode])

	const handleUpload = (file: string) => {
		setViolationImage(file)
		setUpdatedScreenshot(file)
	}

	const handleClose = () => {
		if (updatedScreenshot) onScreenshotChange(updatedScreenshot)
		onSetClose()
		setIsScreenshotInPreview(true)
	}

	const handleSubmit = async () => {
		await setAction(
			[violationId],
			PermissionLevels.Agent,
			true,
		)
		onUpdateViolations()
		onDeselect(violationId)
		onSetClose()
	}

	return (
		<Modal
			size="extraLarge"
			open={isOpen}
			onClose={handleClose}
		>
			<ModalSection>
				<Row align='flex-start'>
					<Col className={clsx(classes.comparisonColumn, classes.leftColumn)}>
						<ImageLabel text={t('originalImage')} type='brand' />
						<ImageComponent
							className={classes.imageInCompare}
							src={originalImage}
							alt={t('originalImage')}
						/>
					</Col>
					<Col className={classes.comparisonColumn} >
						<div className={classes.label}>
							<ImageLabel text={t('potentialViolation')} type='error'/>
						</div>
						{
							addingMode
							&& <AddScreenshot
								violationId={violationId}
								violationUrl={violationUrl}
								onUpload={handleUpload}
								onClose={() => setAddingMode(false)}
							/>
						}
						<CompareDestination
							previewMode={!isScreenshotInPreview}
							image={destinationImage}
							violationUrl={violationUrl}
							setToFullMode={() => setIsScreenshotInPreview(true)}
							addingMode={addingMode}
						/>
						<CompareScreenshot
							previewMode={isScreenshotInPreview}
							image={violationImage || ''}
							violationUrl={violationUrl}
							onChangeScreenshot={() => setAddingMode(true)}
							setToFullMode={() => setIsScreenshotInPreview(false)}
							addingMode={addingMode}
						/>
					</Col>
				</Row>
				<Row justify='flex-end' className={classes.footer} >
					{!addingMode
						&& <PrimaryButton
							type='submit'
							text={t('cleaning:submitViolation')}
							className={classes.submitButton}
							onClick={handleSubmit}
						/>
					}
					<SecondaryButton
						text={t('general:close')}
						className={classes.closeButton}
						onClick={handleClose}
					/>
				</Row>
			</ModalSection>
		</Modal>
	)
}
