import { useEffect } from 'react'

/** Use this hook only if you don't need additional clean up steps */
export function useFetchWithAbortSignal<T>(fetchCallback: (signal: AbortSignal) => Promise<T>, deps: any[]): void {
	useEffect(() => {
		const controller = new AbortController()
		fetchCallback(controller.signal)
		return () => controller.abort()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps])
}
