import { Col, ExtraActionButton, Icons, Typography } from '@copyrightagent/basic-components'
import ButtonLink from 'components/ButtonLink'
import LinkIcon from 'components/LinkIcon'
import { blue, dark72 } from 'lib/colors'
import React, { ReactElement, useState } from 'react'
/* eslint-ignore next-line */
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { updateScreenshot } from 'routes/Private/Cleaning/api'
import { useStyle } from './style'

interface IAddScreenshotProps {
	violationId: string
	violationUrl: string
	onUpload(file: string) : void
	onClose() : void
}

export default function AddScreenshot(props: IAddScreenshotProps): ReactElement {
	const { violationId, violationUrl, onUpload, onClose } = props

	const classes = useStyle()
	const { t } = useTranslation('cleaning')

	const [file, setFile] = useState<Blob | undefined>()
	const [error, setError] = useState<boolean>(false)

	const readFile = async (photo : Blob) => new Promise<string>(resolve => {
		const reader = new FileReader()
		reader.onload = async (event: ProgressEvent<FileReader>) => {
			if (typeof event.target === null) throw new Error('Target on onload is null')
			if (typeof event.target?.result !== 'string') throw new Error('Result on onload is not a string')
			resolve(event.target.result)
		}
		reader.readAsDataURL(photo)
	})

	const onFileDrop = (files: Array<Blob>) => {
		setFile(undefined)
		setError(false)
		if (files.length) files.map(acceptedFile => setFile(acceptedFile))
		if (!files.length) setError(true)
	}

	const saveScreenshot = async () => {
		if (!file) throw new Error('No file selected')
		const screenshot = await readFile(file)

		await updateScreenshot(violationId, screenshot.split('base64,')[1])

		onUpload(URL.createObjectURL(file))
		setFile(undefined)
		onClose()
	}

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		accept: 'image/jpeg, image/png',
		onDrop: onFileDrop,
	})

	return (
		<div className={classes.addScreenshotContainer}>
			<div className={classes.droppingContainer}>
				<div {...getRootProps({ className: classes.dropZone })}>
					{file
						&& <img
							src={URL.createObjectURL(file)}
							alt={t('screenshotPreview')}
							className={classes.preview}
						/>
					}
					<input {...getInputProps()} />
					<Icons.UploadIcon color={blue} size="small"/>
					<Typography variant='body2'>{t('dropFiles')}</Typography>
					{error
						&& <Col align='center' className={classes.errorColumn}>
							<Typography>{t('errorAdding')}</Typography>
							<Typography>{t('wrongFile')}</Typography>
						</Col>
					}
				</div>
			</div>
			<div className={classes.buttonContainer}>
				<div className={classes.iconButtonContainer}>
					<ButtonLink
						text={t('closeEdit')}
						onClick={onClose}>
						<Icons.CloseIcon color={dark72} size="small"/>
					</ButtonLink>
				</div>
				<div className={classes.iconButtonContainer}>
					<LinkIcon href={violationUrl} targetBlank={true}>
						<Icons.LinkIcon external color={dark72} size='small'/>
					</LinkIcon>
				</div>
			</div>
			<div className={classes.submitButtonContainer}>
				<ExtraActionButton
					className={classes.saveButton}
					text={t('saveImage')}
					onClick={saveScreenshot}
					disabled={!file}
				/>
			</div>
		</div>
	)
}
