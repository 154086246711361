import React, { createContext, ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IImageData } from 'lib/entities/Image'
import { Row, Col, Typography, TextLabel, Icons, SecondaryButton, CopyrightAgentLoader } from '@copyrightagent/basic-components'
import MainLayout from 'components/MainLayout'
import StatusIcon from 'components/StatusIcon'
import { dark72 } from 'lib/colors'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import ViolationsList from 'components/ViolationList'
import { loaderCentering } from 'lib/utils'
import { useFetchWithAbortSignal } from 'hooks/fetch'
import { useStyle } from './style'
import { getNthImageInScan, getViolationsInScanCount, getScanName, getImagesByScanIdCount } from '../api'
import OriginalImage from '../../../../components/OriginalImage'

export const HubIdContext = createContext('')

export interface ICleaningViolationPageProps {
	match: {
		params: {
			scanId: string
		}
	}
}

export default function CleaningViolationPage(props: ICleaningViolationPageProps): ReactElement {
	const { scanId } = props.match.params

	const { t } = useTranslation()
	const classes = useStyle()
	const router = useRouter()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [scanName, setScanName] = useState<string>('')
	const [violationsInScanCount, setViolationsInScanCount] = useState<number>()
	const [imagesInScanCount, setImagesInScanCount] = useState<number>(0)
	const [originalImage, setOriginalImage] = useState<IImageData>()
	const [imageOffset, setImageOffset] = useState<number>(0)

	// Sets number of images in scan
	useFetchWithAbortSignal(async signal => {
		const responseGetImagesByScanIdCount = await getImagesByScanIdCount(scanId, signal)
		if (responseGetImagesByScanIdCount.status === 499) return
		setImagesInScanCount(responseGetImagesByScanIdCount.data?.count)
	}, [scanId])

	// Sets violations for scan
	useFetchWithAbortSignal(async signal => {
		const responseGetViolationsInScanCount = await getViolationsInScanCount(scanId, signal)
		if (responseGetViolationsInScanCount.status === 499) return
		setViolationsInScanCount(parseInt(responseGetViolationsInScanCount.data?.[0]?.count, 10) || 0)
	}, [scanId])

	// Sets scan name
	useFetchWithAbortSignal(async signal => {
		const responseGetScanName = await getScanName(scanId, signal)
		if (responseGetScanName.status === 499) return
		setScanName(responseGetScanName.data?.name || '')
	}, [scanId])

	// Sets original image
	useFetchWithAbortSignal(async signal => {
		setIsLoading(true)
		const responseGetFirstImageInScan = await getNthImageInScan(scanId, imageOffset, 650, signal)
		if (responseGetFirstImageInScan.status === 499) return
		setOriginalImage(responseGetFirstImageInScan.data)
		setIsLoading(false)
	}, [scanId, imageOffset])

	const handleGoBack = useCallback(() => {
		router.history.goBack()
	}, [router.history])

	const goToNextImage = useCallback(() => {
		if (imagesInScanCount >= imageOffset + 2) return setImageOffset(prevValue => prevValue + 1)
		return setImageOffset(0)
	}, [imageOffset, imagesInScanCount])

	const header = useMemo(() => (<Row justify='space-between' className={classes.header}>
		<div className={classes.headerLeft}>
			<Icons.ArrowIcon
				direction='left'
				color={dark72}
				className={classes.arrowIcon}
				onClick={handleGoBack}
			/>
			<Typography variant='h4'>{scanName}</Typography>
			<StatusIcon className={classes.statusIcon} state={'open'}/>
			<TextLabel uppercase labelText={t('navigation:cleaning')}/>
		</div>
		{!isLoading
		&& <div className={classes.headerRight}>
			{imagesInScanCount > 1
			&& <SecondaryButton
				text={t('cleaning:nextImage', { n: imageOffset + 1, total: imagesInScanCount })}
				onClick={goToNextImage}
			/>}

			<div className={classes.counter}>
				<Typography variant='body2'>{`${violationsInScanCount} ${t('cleaning:violations')}`}</Typography>
			</div>
		</div>}

	</Row>),
	[
		classes.arrowIcon,
		classes.counter,
		classes.header,
		classes.headerLeft,
		classes.headerRight,
		classes.statusIcon,
		goToNextImage,
		handleGoBack,
		imageOffset,
		imagesInScanCount,
		isLoading,
		scanName,
		t,
		violationsInScanCount,
	])

	return (
		<MainLayout headerChildren={header} isBodyScrollable={true}>
			{isLoading
				? <CopyrightAgentLoader style={loaderCentering}/>
				: <Row className={classes.body} align='flex-start'>
					<Col className={classes.leftSide}>
						{originalImage && <OriginalImage image={originalImage} />}
					</Col>
					<Col className={classes.rightSide} justify='flex-start'>
						{originalImage
						&& <ViolationsList
							scanId={scanId}
							originalImage={originalImage}
							setIsLoading={setIsLoading}
							setImagesInScanCount={setImagesInScanCount}
							setOriginalImage={setOriginalImage}
							setViolationsInScanCount={setViolationsInScanCount}
						/>
						}
					</Col>
				</Row>
			}
		</MainLayout>
	)
}
