import React, { ReactElement } from 'react'
import { PageBody, TextArea, Typography } from '@copyrightagent/basic-components'
import { makeStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import { getCurrentUser } from 'store/utils'

const useStyle = makeStyles({
	card: {
		display: 'flex',
		flexFlow: 'column wrap',
		justifyContent: 'flex-start',
		height: '100%',
		width: '100%',
		borderRadius: 6,
		backgroundColor: 'white',
		marginTop: 0,
		padding: '24px !important',
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
	},
	grid: {
		paddingBottom: 12,
	},
	title: {
		paddingBottom: 12,
	},
})

const UserDetailsSection = (): ReactElement => {
	const classes = useStyle()
	const user = getCurrentUser()
	const title = 'User Details'
	const title2 = 'Profile Picture'
	return (
		<PageBody className={classes.card}>
			<Typography className={classes.title} variant="h5" fontWeight="bolder">{title}</Typography>
			<Grid container className={classes.grid}>
				<Grid item xs={6}>
					<TextArea
						value={user.firstname}
						type='name'
						variant='filled'
						name='username'
						label='User name'
						disabled
					/>
				</Grid>
				<Grid item xs={6}>
					<TextArea
						value={user.email}
						type='email'
						variant='filled'
						name='email'
						label='Email'
						disabled
					/>
				</Grid>
			</Grid>
			<Typography className={classes.title} variant="h5" fontWeight="bolder">{title2}</Typography>
		</PageBody>
	)
}

export default UserDetailsSection
