import { makeInternalAPIRequest } from 'lib/http/ApiProvider'
import IResponse from 'lib/http/Response'

export function getUser(id: string): Promise<IResponse> {
	return makeInternalAPIRequest('get', `/users/${id}`)
}

export function deleteUser(id: string): Promise<IResponse> {
	return makeInternalAPIRequest('delete', `/users/${id}`)
}
