import { makeStyles } from '@material-ui/core'
import { dark72, green, red, white, yellow } from 'lib/colors'

export const useStyles = makeStyles({
	success: {
		backgroundColor: green,
		boxShadow: '10px 10px 40px rgba(72, 210, 160, 0.25)',
	},
	error: {
		backgroundColor: red,
		boxShadow: '10px 10px 40px rgba(222, 69, 91, 0.25)',
	},
	info: {
		backgroundColor: 'white',
		color: dark72,
		boxShadow: '10px 10px 40px rgba(41, 50, 65, 0.1)',
	},
	warning: {
		backgroundColor: yellow,
		color: dark72,
		boxShadow: '10px 10px 40px rgba(248, 197, 28, 0.25)',
	},
	messageError: {
		color: white,
	},
})
