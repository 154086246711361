import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { dark12, white } from 'lib/colors'

interface IThemeProps{
	addingMode: boolean
}

export const useStyle = makeStyles<Theme, IThemeProps>({
	preview: {
		display: ({ addingMode }) => (addingMode ? 'none' : 'flex'),
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		position: 'absolute',
		bottom: 24,
		left: 24,
		height: 96,
		width: 96,
		border: `4px solid ${white}`,
		borderRadius: 6,
		boxShadow: '10px 10px 40px -5px rgba(41, 56, 51, 0.12)',
		overflow: 'hidden',
		backgroundColor: dark12,
		zIndex: 3,
	},
	destinationContainer: {
		position: 'relative',
		display: 'flex',
		height: '100%',
		width: '100%',
	},
	imageDestination: {
		borderRadius: 6,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
	buttonContainer: {
		cursor: 'pointer',
		position: 'absolute',
		bottom: 0,
		left: 0,
		right: 0,
		padding: 5,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		zIndex: 2,
		height: 53,
		background: 'linear-gradient(180deg, rgba(41, 50, 65, 0) 0%, rgba(41, 50, 65, 0.15) 100%)',
		borderRadius: '0 0 6px 6px',
	},
	iconButtonContainer: {
		margin: 3,
	},
})
