import React, { ReactElement, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import {
	PageContainer,
	CopyrightAgentLoader,
	Typography,
} from '@copyrightagent/basic-components'
import { checkIfLoggingIn } from '../LoginPage/firebaseConfig'

const useStyles = makeStyles(() => ({
	loading: {
		justifyContent: 'center',
		alignItems: 'center',
	},
	loadingText: {
		marginTop: 20,
		fontWeight: 800,
	},
}))

const LogginInPage = (): ReactElement => {
	const { t } = useTranslation()
	const classes = useStyles()

	useEffect(() => {
		checkIfLoggingIn()
	}, [])

	return (
		<PageContainer className={classes.loading}>
			<CopyrightAgentLoader />
			<Typography variant='subtitle1' className={classes.loadingText}>{t('loggingIn/loading')}</Typography>
		</PageContainer>
	)
}

export default LogginInPage
