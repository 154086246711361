import React, { ReactElement, ReactNode } from 'react'
import useAuthenticationRouting from '../hooks/useAuthenticationRouting'

interface IProps {
	children: ReactNode
}

export default function RootWrapper(props: IProps): ReactElement {
	useAuthenticationRouting()

	return (
		<>
			{props.children}
		</>
	)
}
