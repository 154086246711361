import React, { ReactElement, useEffect } from 'react'
import { Icons, PageContainer, Row, Typography } from '@copyrightagent/basic-components'
import { NewLogin } from '@copyrightagent/login-component'
import { Link } from '@material-ui/core'
import { Trans } from 'react-i18next'
import trackEvent from 'hooks/useAnalytics'
import { getSessionToken, logout } from 'store/utils'
import { attemptLogin } from './firebaseConfig'
import useStyle from './style'

const {
	REACT_APP_API_URL: API_URL,
} = process.env

// eslint-disable-next-line @typescript-eslint/no-var-requires
const translations = require('./login.json')

const LoginPage = (): ReactElement => {
	const classes = useStyle()

	useEffect(() => {
		logout()
	}, [])

	return (
		<>
			<Icons.Logo className={classes.logo} />
			<PageContainer className={classes.page}>
				<NewLogin
					login={attemptLogin}
					token={getSessionToken()}
					api={`${API_URL}/api`}
					translations={translations}
					tracking={trackEvent}
				/>
				<Row className={classes.bottom}>
					<Typography className={classes.terms}>
						<Trans
							components={[
								<Link
									key='contact'
									className={classes.termsLink}
									href='https://copyrightagent.com/contact-us/'
									target='_blank'
								/>,
								<Link
									key='privacy'
									className={classes.termsLink}
									href='https://copyrightagent.com/privacy/'
									target='_blank'
								/>,
							]}>
							{translations.terms}
						</Trans>
					</Typography>
				</Row>
			</PageContainer>
		</>
	)
}

export default LoginPage
