import React, { ReactNode, createContext, useContext } from 'react'
import { BrowserRouter, Route, RouteChildrenProps } from 'react-router-dom'

export type TUseRouterReturn = RouteChildrenProps<Record<string, string | undefined>, unknown>

export const RouterContext = createContext<TUseRouterReturn | undefined>(undefined)

interface ICustomBrowserRouterProps {
	children: ReactNode
}

const CustomBrowserRouter = (props: ICustomBrowserRouterProps): JSX.Element => (
	<BrowserRouter>
		<Route>
			{routeProps => (
				<RouterContext.Provider value={routeProps}>
					{props.children}
				</RouterContext.Provider>
			)}
		</Route>
	</BrowserRouter>
)

export const useRouter = (): TUseRouterReturn => {
	const context = useContext(RouterContext)
	if (!context) throw new Error('useRouter must be used within CustomBrowserRouter')

	return context
}

export default CustomBrowserRouter
