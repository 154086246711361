import React, { ReactElement } from 'react'
import { NavLink, Redirect, Route, Switch } from 'react-router-dom'
import { Typography } from '@copyrightagent/basic-components'
import { List, ListItem, ListItemText } from '@material-ui/core'
import { IMenuItems } from 'routes/Private/SettingsPage/settingsMenuItems'
import { RouteUrls } from 'routes/RouteUrls'
import { useStyles } from './style'

interface ISubMenuProps {
	title: string,
	menuItems: Array<IMenuItems>
}

const SubMenu = (props: ISubMenuProps): ReactElement => {
	const { title, menuItems } = props

	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.leftColumn}>
				<List className={classes.card}>
					<Typography variant='h5' className={classes.h5}>{title}</Typography>
					{menuItems.map((menuItem, index) => (
						<ListItem key={index}
							button
							component={NavLink}
							classes={{
								root: classes.listItem,
							}}
							to={menuItem.path}
							activeClassName={classes.selectedListItem}
						>
							<ListItemText primary={menuItem.title} className={classes.listItemText} />
						</ListItem>
					))}
				</List>
			</div>
			<div className={classes.rightColumn}>
				<Switch>
					{menuItems.map((menuItem, index) => (
						<Route key={index} exact path={menuItem.path}>
							{menuItem.component()}
						</Route>
					))}
					<Redirect from={RouteUrls.settings} to={menuItems[0].path} />
				</Switch>
			</div>
		</div>
	)
}
export default SubMenu
