import { makeStyles } from '@material-ui/core'
import { dark12, dark72, white } from 'lib/colors'

export const useStyle = makeStyles({
	header: {
		width: '100%',
		height: 44,
		zIndex: 2,
		marginBottom: 18,
	},
	headerLeft: {
		display: 'flex',
		alignItems: 'center',
	},
	headerRight: {
		display: 'flex',
		alignItems: 'center',
	},
	counter: {
		backgroundColor: white,
		padding: 8,
		borderRadius: 6,
		border: `1px solid ${dark12}`,
		'& p': {
			color: dark72,
		},
		marginLeft: 8,
		height: 40,
	},
	body: {
		height: '100%',
		overflow: 'hidden',
	},
	leftSide: {
		minWidth: 634,
		maxWidth: 634,
		height: 638,
		position: 'relative',
		borderRadius: 6,
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
		marginRight: 16,
		overflow: 'hidden',
	},
	rightSide: {
		backgroundColor: '#fff',
		borderRadius: 6,
		padding: 24,
		position: 'relative',
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25)',
		alignSelf: 'stretch',
		marginBottom: 48,
		width: '100%',
	},
	statusIcon: {
		margin: '0 16px',
	},
	arrowIcon: {
		marginRight: 12,
		cursor: 'pointer',
	},
})
