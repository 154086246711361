import { createStore, combineReducers, Store, EmptyObject, AnyAction } from 'redux'
import { persistStore, persistReducer, Persistor } from 'redux-persist'
import { PersistPartial } from 'redux-persist/lib/persistReducer'
import storage from 'redux-persist/lib/storage'
import * as reducers from '.'
import { IState } from './auth'
import { ISnackbarState } from './snackbar'

declare global {
	interface Window {
		// () => never is a bit safer approach than using 'any'
		// https://stackoverflow.com/questions/52800877/error-with-redux-devtools-extension-using-ts-property-redux-devtools-extens
		__REDUX_DEVTOOLS_EXTENSION__: () => never
	}
}

declare global {
	interface Window {
		// () => never is a bit safer approach than using 'any'
		// https://stackoverflow.com/questions/52800877/error-with-redux-devtools-extension-using-ts-property-redux-devtools-extens
		__REDUX_DEVTOOLS_EXTENSION__: () => never
	}
}

// eslint-disable-next-line no-underscore-dangle
const extension = window.__REDUX_DEVTOOLS_EXTENSION__

const rootReducer = combineReducers(reducers)

const persistConfig = {
	storage,
	key: 'redux',
	whitelist: ['AuthState'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
	persistedReducer,
	extension && extension(),
)

const persistor = persistStore(store)

interface IStoreProps {
	store: typeof store,
	persistor: Persistor
}

export default (): IStoreProps => ({ store, persistor })
