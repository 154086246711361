import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'
import { Tail } from 'lib/types'
import { getSessionToken, setSessionToken } from 'store/utils'
import IResponse, { createResponse } from './Response'

const {
	REACT_APP_API_URL: API_URL,
} = process.env

type TRequestConfig = TJsonPayload | FormData

export type TJsonPayload = {
	[name: string]: any
}

type TMethod = 'get' | 'post' | 'put' | 'patch' | 'delete'
type TPrefix = '/api' | '/backoffice'

export async function makeInternalRequest(
	prefix: TPrefix,
	method: TMethod,
	url: string,
	config?: TRequestConfig,
	signal?: AbortSignal,
	headers?: TJsonPayload,
	responseType?: ResponseType,
): Promise<IResponse> {
	setSessionToken()

	const defaultHeaders = {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: getSessionToken(),
	}

	const axiosParameters : AxiosRequestConfig = {
		url: `${API_URL}${prefix}${url}`,
		method,
		headers: { ...defaultHeaders, ...headers },
		responseType: responseType || 'json',
		signal,
	}

	if (method === 'get' || method === 'delete') axiosParameters.params = { ...config }
	if (method === 'post' || method === 'put' || method === 'patch') axiosParameters.data = config

	return new Promise(resolve => {
		axios(axiosParameters)
			.then((response: AxiosResponse) => resolve(createResponse(response)))
			.catch((error: AxiosError) => {
				if (error.code === 'ERR_CANCELED') return resolve(createResponse({ ...error.response!, status: 499 }))
				if (!error.response) return
				return resolve(createResponse(error.response))
			})
	})
}

type TRequestFunc = typeof makeInternalRequest

export const makeInternalAPIRequest = (
	...params: Tail<Parameters<TRequestFunc>>
): Promise<IResponse> => makeInternalRequest('/api', ...params)

export const makeInternalBackofficeRequest = (
	...params: Tail<Parameters<TRequestFunc>>
): Promise<IResponse> => makeInternalRequest('/backoffice', ...params)
