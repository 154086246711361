import React, { ReactElement, ReactNode } from 'react'
import Navigation from 'routes/Private/Navigation'
import PageContainerResponsive from 'components/PageContainerResponsive'
import { useStyles } from './style'

interface IMainLayoutProps {
	title?: string,
	headerChildren?: ReactNode
	children: ReactNode
	isBodyScrollable?: boolean
}

const MainLayout = (props: IMainLayoutProps): ReactElement => {
	const { title, headerChildren, children, isBodyScrollable } = props

	const classes = useStyles()

	return (
		<div id="scrollableDiv" className= {classes.root}>
			<Navigation />
			<PageContainerResponsive title={title} headerChildren={headerChildren} isBodyScrollable={isBodyScrollable}>
				{children}
			</PageContainerResponsive>
		</div>
	)
}

export default MainLayout
