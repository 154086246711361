import React, { ReactElement, useState } from 'react'
import {
	IScanViolationData,
	PermissionLevels,
	RejectReason,
	setAction,
	getImagesByScanIdCount,
	getNthImageInScan,
	getViolationsPerImageInScan,
	getViolationsInScanCount,
} from 'routes/Private/Cleaning/api'
import { IImageData } from 'lib/entities/Image'
import { useFetchWithAbortSignal } from 'hooks/fetch'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import Violation from '../Violation'
import CleaningActionsBanner from '../CleaningActionsBanner'
import { useStyle } from './style'

interface IViolationsListProps {
	scanId: string,
	originalImage: IImageData
	setIsLoading(arg: boolean): void
	setImagesInScanCount(arg:number): void
	setOriginalImage(arg: IImageData): void
	setViolationsInScanCount(arg: number): void
}

export default function ViolationsList(props: IViolationsListProps): ReactElement {
	const {
		scanId,
		originalImage,
		setIsLoading,
		setImagesInScanCount,
		setOriginalImage,
		setViolationsInScanCount,
	} = props

	const classes = useStyle()
	const router = useRouter()

	const [selectedViolations, setSelectedViolations] = useState<IScanViolationData[]>([])
	const [allImageViolations, setAllImageViolations] = useState<IScanViolationData[]>([])

	// Sets violations for image
	useFetchWithAbortSignal(async signal => {
		if (originalImage.photo_id) {
			const responseGetViolationsPerImageInScan = await getViolationsPerImageInScan(
				scanId,
				originalImage.photo_id,
				signal,
			)
			if (responseGetViolationsPerImageInScan.status === 499) return
			setAllImageViolations(responseGetViolationsPerImageInScan.data)
		}
	}, [originalImage.photo_id, scanId])

	const toggleSelectAll = () => {
		if (selectedViolations.length === allImageViolations.length) return setSelectedViolations([])
		return setSelectedViolations(allImageViolations)
	}

	const addToSelected = (incidentId: string) => {
		const selectedIncident = allImageViolations.find(imageViolation => imageViolation.violation_id === incidentId)
		if (selectedIncident) setSelectedViolations(prevState => [...prevState, selectedIncident])
	}

	const removeFromSelected = (incidentId: string) => {
		setSelectedViolations(prevState => prevState.filter(incident => incident.violation_id !== incidentId))
	}

	const refetchViolations = async () => {
		// Sets violations for scan
		const responseGetViolationsInScanCount = await getViolationsInScanCount(scanId)
		const violationsCount = parseInt(responseGetViolationsInScanCount.data?.[0]?.count, 10) || 0
		setViolationsInScanCount(violationsCount)

		// Sets violations for image
		if (originalImage.photo_id) {
			const responseGetViolationsPerImageInScan = await getViolationsPerImageInScan(
				scanId,
				originalImage.photo_id,
			)
			setAllImageViolations(responseGetViolationsPerImageInScan.data)
		}
	}

	const refetchFirstImage = async () => {
		setIsLoading(true)

		// Sets number of images in scan
		const responseGetImagesByScanIdCount = await getImagesByScanIdCount(scanId)
		const imageCount = parseInt(responseGetImagesByScanIdCount.data?.count, 10) || 0
		setImagesInScanCount(imageCount)

		// If scan has 0 images left, go to scan list
		if (imageCount === 0) return router.history.push(RouteUrls.cleaning)

		// Sets original image
		const responseGetFirstImageInScan = await getNthImageInScan(scanId, 0, 650)
		setOriginalImage(responseGetFirstImageInScan.data)

		// Sets violations
		await refetchViolations()

		setIsLoading(false)
	}

	const approveViolations = async () => {
		let violationIds: Array<string>
		if (selectedViolations.length !== 0) violationIds = selectedViolations.map(incident => incident.violation_id)
		else violationIds = allImageViolations.map(incident => incident.violation_id)
		await setAction(
			violationIds,
			PermissionLevels.Agent,
			true,
		)

		if (allImageViolations.length === violationIds.length) return refetchFirstImage()

		refetchViolations()
		setSelectedViolations([])
	}

	const discardViolations = async (rejectReason?: RejectReason) => {
		const violationIds = selectedViolations.map(selectedViolation => selectedViolation.violation_id)
		await setAction(
			violationIds,
			PermissionLevels.Agent,
			false,
			rejectReason,
		)

		if (allImageViolations.length === violationIds.length) return refetchFirstImage()

		refetchViolations()
		setSelectedViolations([])
	}

	return (
		<>
			<div id="scrollableDiv" className={classes.scrollableDiv}>
				<div className={classes.grid}>
					{allImageViolations.map(imageViolation => <Violation
						originalImage={originalImage.url || ''}
						destinationImage={imageViolation.destination_image_url || ''}
						key={imageViolation.violation_id}
						id={imageViolation.violation_id}
						violationUrl={imageViolation.url}
						isSelected={selectedViolations.some(
							selectedViolation => selectedViolation.violation_id === imageViolation.violation_id,
						)}
						onSelect={addToSelected}
						onDeselect={removeFromSelected}
						onUpdateViolations={refetchViolations}
					/>)
					}
				</div>

			</div>
			<CleaningActionsBanner
				imageViolationsCount={allImageViolations.length}
				selectedViolationsCount={selectedViolations.length}
				onToggleSelectAll={toggleSelectAll}
				onDiscardViolations = {discardViolations}
				onApproveViolations={approveViolations}
			/>
		</>
	)
}
