import React, { ReactElement } from 'react'
import { Link } from '@material-ui/core'
import { useStyle } from './style'

export interface ILinkIconProps {
	href: string,
	targetBlank?: boolean,
	children: ReactElement
}

export default function LinkIcon(props: ILinkIconProps): ReactElement {
	const { href, targetBlank = false, children } = props

	const classes = useStyle()

	return (
		<Link
			href={href}
			target={targetBlank ? '_blank' : '_self'}
			rel={targetBlank ? 'noopener noreferrer' : ''}
			className={classes.linkIcon}
		>
			{children}
		</Link>
	)
}
