import React from 'react'
import { Provider } from 'react-redux'
import WebFont from 'webfontloader'
import 'normalize.css/normalize.css'
import { ThemeProvider } from '@material-ui/styles'
import theme from './lib/theme'
import getStore from './store/store'
import AppRouter from './routes'
import './lib/locales'

WebFont.load({
	google: {
		families: ['Open Sans:400,400i,600,700'],
	},
})

const { store } = getStore()

const App: React.FC = () => (
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<AppRouter />
		</ThemeProvider>
	</Provider>
)

export default App
