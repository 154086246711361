import { Col, Row, Typography } from '@copyrightagent/basic-components'
import ImageComponent from 'components/ImageComponent'
import { useFetchWithAbortSignal } from 'hooks/fetch'
import { IImageData } from 'lib/entities/Image'
import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'routes/CustomBrowserRouter'
import { RouteUrls } from 'routes/RouteUrls'
import { getSelectedClient } from 'store/utils'
import { getNthImageInScan, IScan, IScanData } from '../../routes/Private/Cleaning/api'
import { useStyle } from './style'

interface IOverviewListItemProps {
	scan: IScanData,
}

export default function OverviewListItem(props: IOverviewListItemProps): ReactElement {
	const { scan } = props

	const classes = useStyle()
	const { t } = useTranslation()
	const router = useRouter()
	const [scanBatch, setScanBatch] = useState<IScan>()

	// Sets scan batches
	useFetchWithAbortSignal(async signal => {
		const responseGetFirstImageInScan = await getNthImageInScan(scan.client_id, 0, 300, signal)
		if (responseGetFirstImageInScan.status === 499) return
		const image: IImageData = responseGetFirstImageInScan.data
		setScanBatch({
			scanId: scan.client_id,
			scanName: scan.name,
			firstImageId: image.photo_id,
			firstImageSrc: image.url,
			violationsCount: scan.count,
		})
	}, [scan.count, scan.client_id, scan.name])

	const handleRedirect = () => {
		if (scanBatch) router.history.push(`${RouteUrls.cleaning}/${scanBatch.scanId}`)
	}

	return (
		<Row
			className={classes.overviewItem}
			onClick={handleRedirect}
			align='stretch'
			id={scan.client_id}
		>
			{scanBatch
			&& <>
				<Row className={classes.imageRow}>
					<Row className={classes.imageContainer}>
						<ImageComponent
							src={scanBatch.firstImageSrc || ''}
							alt={`${getSelectedClient().name}-${t('firstImageToReview')}`}
						/>
						<Typography variant='body2' className={classes.violationsNumber}>
							{scanBatch?.violationsCount}
						</Typography>
					</Row>
				</Row>
				<Row className={classes.infoRow} justify='space-between'>
					<Col style={{ textAlign: 'right' }}>
						<Typography variant='body2' className={classes.label}>{t('general:id')}</Typography>
						<Typography variant='body2'>{scanBatch.scanName || ''}</Typography>
					</Col>
				</Row>
			</>
			}
		</Row>
	)
}
