import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'
import LoginPage from './Public/LoginPage'
import CustomSnackBar from '../components/CustomSnackBar'
import CustomBrowserRouter from './CustomBrowserRouter'
import RootWrapper from './RootWrapper'
import LogginInPage from './Public/LoggingInPage'
import { RouteUrls } from './RouteUrls'
import NotFoundPage from './Public/NotFoundPage'
import FrontPage from './Public/FrontPage'
import Dashboard from './Private/Dashboard'
import SettingsPage from './Private/SettingsPage'
import Cleaning from './Private/Cleaning'
import CleaningViolationPage from './Private/Cleaning/CleaningViolationPage'

function AppRouter(): ReactElement {
	return (
		<CustomBrowserRouter>
			<RootWrapper>
				<Switch>
					<Route exact path={RouteUrls.landingPage} component={FrontPage} />
					<Route exact path={RouteUrls.login} component={LoginPage} />
					<Route exact path={RouteUrls.loggingIn} component={LogginInPage} />
					<Route exact path={RouteUrls.dashboard} component={Dashboard} />
					<Route path={RouteUrls.settings} component={SettingsPage} />
					<Route exact path={`${RouteUrls.cleaning}/:scanId`} component={CleaningViolationPage} />
					<Route path={RouteUrls.cleaning} component={Cleaning} />
					<Route component={NotFoundPage} />
				</Switch>
				<CustomSnackBar />
			</RootWrapper>
		</CustomBrowserRouter>
	)
}

export default AppRouter
