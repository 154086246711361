import { makeStyles } from '@material-ui/core'
import { dark72, white } from 'lib/colors'

export const useStyle = makeStyles({
	buttonIcon: {
		cursor: 'pointer',
		backgroundColor: white,
		borderRadius: 6,
		display: 'flex',
		alignItems: 'center',
		padding: (props: { text: string }) => (props.text ? '3px 6px' : '3px'),
		boxShadow: '0px 0px 1px rgba(41, 50, 65, 0.25), 10px 10px 40px rgba(41, 50, 65, 0.15)',
		height: 20,
		'& p': {
			color: dark72,
			fontSize: 10,
			paddingRight: 6,
			paddingLeft: 3,
			whiteSpace: 'nowrap',
		},
	},
})
